import { formValueSelector } from 'redux-form';
import formatCustomerFullName from '../../../utilities/formatCustomerFullName';
import decryptedSSNFormatter from '../../../formatters/decryptedSSN';
import type { AboutYouFields, NewCustomerApplicationForm, Terms } from '../applications.actions';
import { filterInitialsFromAgreements } from '../applications.actions';
import type { Name } from '../../../utilities/types';
import type { ReduxState } from '../../../reducers/index';
import { NAO_FORM_ID } from '../../../utilities/accountOpeningFlowType';
import { PERSONAL_INFORMATION_SECTION_ID } from '../../../form.constants';
import { parseFieldNameValues } from '../../../formatters/fieldNameValues';

export const buildNameFromFormValues = ({
  firstName,
  middleName = '',
  lastName,
}: AboutYouFields) => {
  const name: Name = { firstName, middleName, lastName, prefix: '', suffix: '' };
  return formatCustomerFullName(name);
};

export const getEAOName = (state: ReduxState) => {
  return state.applications.customerInfo
    ? formatCustomerFullName(state.applications.customerInfo.name)
    : '';
};
export const getNAOName = (state: ReduxState) => {
  const formValues = formValueSelector(NAO_FORM_ID)(state, PERSONAL_INFORMATION_SECTION_ID);
  return buildNameFromFormValues(formValues);
};

export const buildSSN = ({ socialSecurityNumber }) => decryptedSSNFormatter(socialSecurityNumber);

export const getNaoSSN = (state: ReduxState) =>
  buildSSN(formValueSelector(NAO_FORM_ID)(state, PERSONAL_INFORMATION_SECTION_ID));

export const getEaoSSN = (state: ReduxState) =>
  decryptedSSNFormatter(
    state.applications.customerInfo && state.applications.customerInfo.decryptedTaxId
  );

export const getEtinAccepted = (agreements: Terms) => {
  const parsedEtin = agreements.etin && parseFieldNameValues(agreements.etin);
  return { ...parsedEtin };
};

export const extractTermsAndWithholding = (formData: NewCustomerApplicationForm) => {
  const etinData = formData.terms && getEtinAccepted(formData.terms);
  const terms = parseFieldNameValues(filterInitialsFromAgreements(formData.terms.agreements));
  delete terms[''];
  // deleting withholding from etinData object as termsAccepted don't need the withholding property.
  delete etinData?.withholding;
  return {
    ...terms,
    ...etinData,
  };
};
