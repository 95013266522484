export const breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
} as const;

export type Breakpoints = typeof breakpoints;

export default {
  up: (screensize: string) => `@media (min-width: ${breakpoints[screensize]}px)`,
  down: (screensize: string) => `@media (max-width: ${breakpoints[screensize]}px)`,
};
