import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { SynchronyInfo } from './components/cms/blockText.constants';

const IgniteHelmet = () => {
  const location = useLocation();

  return (
    <Helmet>
      <title data-test="bank-name">{SynchronyInfo.BANKNAME}</title>
      <link rel="manifest" href="/manifest.json" />
      <meta name={`${location}`} />
    </Helmet>
  );
};

export default IgniteHelmet;
