import type { ApplicationStatus } from '../../containers/newAccountOpening/newAccountOpening.constants';
import type { ChooseResponse } from '../../services/dynamicYield.service';

export const DynamicYieldBaseUrls = Object.freeze({
  DIRECT_BASE_URL: 'https://direct.dy-api.com/v2/serve',
  COLLECT_BASE_URL: 'https://direct-collect.dy-api.com/v2/collect',
  PIXEL_BASE_URL: 'https://px.dynamicyield.com',
});

const APPLICATION_COMPLETE_EXISTING = 'DY_Existing_Application_Complete';
const APPLICATION_START_EXISTING = 'DY_Existing_Application_Start';
const APPLICATION_COMPLETE_NEW = 'DY_New_Application_Complete';
const APPLICATION_START_NEW = 'DY_New_Application_Start';
const APPLICATION_PRE_VIEW = 'DY_Pre_Application_View ';

export const DyEventNames = Object.freeze({
  APPLICATION_PRE_VIEW,
  APPLICATION_COMPLETE_EXISTING,
  APPLICATION_START_EXISTING,
  APPLICATION_COMPLETE_NEW,
  APPLICATION_START_NEW,
});

export const PixelTracking = Object.freeze({
  SEC: window.__config__.DY_SECTION_ID,
});

// All of the page types that DY allows. We will probably not use all of these.
export const PageTypes = Object.freeze({
  CATEGORY: 'CATEGORY',
  CART: 'CART',
  HOMEPAGE: 'HOMEPAGE',
  OTHER: 'OTHER',
  PRODUCT: 'PRODUCT',
});

export type PageType = typeof PageTypes[keyof typeof PageTypes];

export interface RecommendationContext {
  type: PageType;
  data?: string[];
  lng?: string;
}

export interface DyObject {
  recommendationContext?: RecommendationContext;
  [key: string]: unknown;
}

export const PageCategories = Object.freeze({
  ACCOUNT: 'Account',
  COMMUNICATION: 'Communication',
  EAO: 'EAO',
  FUNDING: 'Funding',
  NAO: 'NAO',
  TRANSFERS: 'Transfers',
  USER: 'User',
});

export type PageCategory = typeof PageCategories[keyof typeof PageCategories];

// Need to declare these as constants so that their type is more specific than just string (thanks Flow 🙄)
const EAO_APPLICATION_COMPLETE = 'EAO Application Complete';
const EAO_APPLICATION_START = 'EAO Application Start';
const NAO_APPLICATION_COMPLETE = 'NAO Application Complete';
const NAO_APPLICATION_START = 'NAO Application Start';
const WHATS_NEW_DISMISS = 'Whats New Dismiss';
const DASHBOARD_AUDIENCE_DATA = 'Dashboard Audience Data';

export const EventNames = Object.freeze({
  // When adding another event name here, make sure to also add a mapping for it to NameToPropertiesMapper below.
  EAO_APPLICATION_COMPLETE,
  EAO_APPLICATION_START,
  NAO_APPLICATION_COMPLETE,
  NAO_APPLICATION_START,
  WHATS_NEW_DISMISS,
  DASHBOARD_AUDIENCE_DATA,
});

export type EventName = typeof EventNames[keyof typeof EventNames];
export type AudienceDataType = {
  customerType: string;
  customerStatus: string;
  occupation: string;
  customerAge: string;
  isAffinityCustomer: boolean;
  accountDetails: string;
};

export type NameToPropertiesMapper =
  | ((arg1: typeof NAO_APPLICATION_START | typeof EAO_APPLICATION_START) => void)
  | ((arg1: typeof NAO_APPLICATION_COMPLETE | typeof EAO_APPLICATION_COMPLETE) => {
      status: ApplicationStatus;
    })
  | ((arg1: typeof WHATS_NEW_DISMISS) => {
      version: string;
    })
  | ((arg1: typeof DASHBOARD_AUDIENCE_DATA) => AudienceDataType);

/**
 * Essentially this type allows Flow to dynamically decide the shape of the properties object depending
 * on which specific EventName is passed in. So for example, if we use NAO_APPLICATION_START, it will only
 * accept undefined for the properties, but if we use NAO_APPLICATION_COMPLETE, it will only accept
 * something like { status: 'CREATED' } for the properties.
 */
export type EventProperties<T extends EventName> = ReturnType<NameToPropertiesMapper>;

export const DynamicYieldCampaignNames = Object.freeze({
  DASHBOARD_TILE_CAMPAIGN: 'Dashboard Tile Campaign',
  WHATS_NEW_CAMPAIGN: "What's New Campaign",
});

export const CookieNames = Object.freeze({
  DYID_SERVER: '_dyid_server',
  DYJSESSION: '_dyjsession',
  DYID: '_dyid',
});

export const findDYCampaignData = (campaignName: string, response?: ChooseResponse | null) => {
  return response?.choices.find((choice) => choice.name === campaignName);
};

export const findDYCampaignGroupData = (response?: ChooseResponse | null) => {
  return response?.choices;
};

// https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
export const getCookieValue = (cookieName) =>
  document.cookie
    .split('; ')
    .find((row) => row.startsWith(cookieName))
    ?.split('=')[1];
