import { withStyles } from '@material-ui/core/styles';
import { Toolbar } from '@material-ui/core';
import classNames from 'classnames';
import * as React from 'react';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { MobileMenuToggle } from './headerLinks';
import Logo from '../logo/logo';
import styles from './header.styles';

type HeaderToolbarProps = {
  classes: ClassNameMap;
  onMouseEnter: React.MouseEventHandler;
  menuToggle: () => void;
  hamburgerRef: React.RefObject<HTMLButtonElement>;
  isMenuExpanded: boolean;
  isDark: boolean;
  title: string;
  active: boolean;
  isScrolledDown: boolean;
  isDashboardCaching: boolean;
  transitionState: string;
};

const HeaderToolbar = withStyles(styles)((headerToolbarProps: HeaderToolbarProps) => {
  const {
    classes = {},
    onMouseEnter,
    menuToggle,
    hamburgerRef,
    isMenuExpanded,
    isDark,
    title,
    active,
    isScrolledDown,
    isDashboardCaching,
    transitionState,
  } = headerToolbarProps;

  const isDarkTheme = isDark && !active;

  return (
    <Toolbar
      className={classNames(
        classes.toolbar,
        !isDashboardCaching ? classes.itemsHoverDeactive : '',
        transitionState === 'opened' ? classes.toolbarOpened : ''
      )}
      data-test="header-toolbar"
    >
      <Logo
        className={isScrolledDown && !active && title ? classes.logoHidden : classes.logo}
        isDark={!isDarkTheme}
        onMouseEnter={onMouseEnter}
      />

      {!isDashboardCaching && (
        <MobileMenuToggle
          isDark={isDark}
          className={classNames(classes.mobileVisible, {
            [classes.focusDarkThemeMenu]: isDarkTheme,
            [classes.focusLightThemeMenu]: !isDarkTheme,
          })}
          isMenuExpanded={isMenuExpanded}
          menuToggle={menuToggle}
          aria-label="Open drawer"
          ref={hamburgerRef}
        />
      )}
    </Toolbar>
  );
});

export default HeaderToolbar;
