import { StyleRules } from '@material-ui/core';
import pxToRem from '../../utilities/pxToRem';
import type { Theme } from '../../utilities/types';
import Colors from '../colors/colors';

export default (theme: Theme): StyleRules => ({
  headerLinksSection: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: 0,
    paddingRight: pxToRem(24),
    marginRight: pxToRem(24),
    [theme.breakpoints.down('md')]: {
      marginLeft: pxToRem(8),
      marginRight: pxToRem(8),
      paddingRight: pxToRem(8),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      flexWrap: 'nowrap',
    },
  },
  headerLinkTray: {
    width: 'auto',
    marginRight: 'auto',
    '&:last-of-type': {
      marginRight: pxToRem(8),
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: pxToRem(35),
    },
  },
  subLinkItem: {
    cursor: 'default',
    padding: '0',
    transition: 'opacity 0.25s ease-out',
    opacity: 1,
    width: pxToRem(100),
    overflow: 'visible',
    whiteSpace: 'nowrap',
  },
  subLinkItemInactive: {
    pointerEvents: 'none',
  },
  menuLink: {
    background: 'none',
    pointerEvents: 'none',
    border: 'none',
    lineHeight: 1.35,
    padding: `${pxToRem(10)} ${pxToRem(15)}`,
    textDecoration: 'none !important',
    marginBottom: pxToRem(10),

    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      // 17 is the smallest padding value that does not cause the top header links to jump when
      // the Loading Accounts items disappears. Be careful changing this.
      padding: `${pxToRem(8)} ${pxToRem(17)}`,
    },
    [theme.breakpoints.up('lg')]: {
      padding: `${pxToRem(8)} ${pxToRem(24)}`,
    },
  },
  subLink: {
    background: 'none',
    border: 'none',
    textDecoration: 'none',
    textTransform: 'none',
    padding: `${pxToRem(6)} ${pxToRem(15)}`,
    display: 'inline-block',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'none',
    },
    '&:hover > *': {
      color: Colors.clickable,
      fontWeight: 500,
    },
    [theme.breakpoints.up('md')]: {
      padding: `${pxToRem(0)} ${pxToRem(17)}`,
    },
    [theme.breakpoints.up('lg')]: {
      padding: `${pxToRem(0)} ${pxToRem(24)}`,
    },
  },
  closeIcon: {
    width: pxToRem(32),
    height: pxToRem(32),
  },
  hamburgerIcon: {
    width: pxToRem(34),
    height: pxToRem(24),
    margin: `${pxToRem(6)} 0`,
  },
  activeHeaderLink: {
    borderBottom: `3px solid ${Colors.synchronyGold}`,
    paddingBottom: pxToRem(4),
  },
  headerLinksRight: {
    [theme.breakpoints.only('md')]: {
      maxWidth: pxToRem(280),
    },
    [theme.breakpoints.only('lg')]: {
      maxWidth: pxToRem(380),
    },
    minWidth: pxToRem(103),

    [theme.breakpoints.up('lg')]: {
      '&:not(:last-child)': {
        // Prevents Send Message sublink from overlapping Security
        minWidth: pxToRem(103),
      },
    },
  },
  cachedMenu: {
    width: '100%',
    justifyContent: 'right',
  },
  dashboardCachingHeader: {
    display: 'flex',
    marginRight: 0,
    [theme.breakpoints.up('sm')]: {
      marginRight: pxToRem(20),
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: pxToRem(12),
    },
  },

  dashboardCachingElements: {
    paddingTop: pxToRem(12),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  noProfile: {
    paddingTop: pxToRem(0),
    marginBottom: pxToRem(0),
  },
  dashboardCachingText: {
    color: '#3b3c43',
    marginRight: `${pxToRem(64)} !important`,
    fontWeight: 'bold',
    fontSize: pxToRem(14),
    letterSpacing: pxToRem(1.12),
  },
  secondaryButton: {
    margin: pxToRem(0),
    padding: pxToRem(4),
    paddingLeft: pxToRem(12),
    paddingRight: pxToRem(12),
    fontSize: pxToRem(12),
    letterSpacing: pxToRem(0.28),
  },
  cachingSignOutButton: {
    marginRight: pxToRem(20),
    [theme.breakpoints.up('sm')]: {
      marginRight: pxToRem(0),
    },
  },
  menuLinkRight: {
    paddingRight: 0,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: pxToRem(15),
    },
    whiteSpace: 'nowrap',
  },
  menuLinkWithIcon: {
    // Offset to keep text in line with sublinks
    position: 'relative',
    left: pxToRem(-20),
  },
  bold: {
    fontWeight: 'bold',
  },
  inboxIcon: {
    width: pxToRem(12),
    height: pxToRem(12),
    marginRight: pxToRem(8),
    color: Colors.synchronyGold,
  },
});
