import generateEditRemoveId from '../../utilities/generateEditRemoveId';
import { Beneficiary } from './beneficiaries.constants';
import { BeneficiariesResponse } from './beneficiaries.service';

const storeNextLineItem = (beneficiaries: Beneficiary[], beneficiaryToRemove: Beneficiary) => {
  let nextLineItem;
  if (beneficiaries.length > 1) {
    const currentIndex = beneficiaries.findIndex(
      (transferList) => transferList.editId === beneficiaryToRemove.editId
    );
    if (currentIndex !== -1) {
      nextLineItem = beneficiaries[currentIndex + 1];
    }
  }
  return nextLineItem;
};
export default storeNextLineItem;

export const setFocusOnAdd = () => {
  const addButton: HTMLElement = document.querySelector('[data-test="beneficiaries-add-button"]');
  if (addButton) {
    requestAnimationFrame(() => {
      addButton.focus();
    });
  }
};

export const checkEditValue = (lineItem: Beneficiary) => {
  let editButton;
  if (!lineItem?.editId) {
    editButton = lineItem;
  } else {
    editButton = document.querySelector(`[id=${lineItem.editId}]`);
  }
  requestAnimationFrame(() => {
    editButton.focus();
  });
};

export const afterRemoveGetLineItem = (nextLineItem: Beneficiary) => {
  if (nextLineItem && Object.keys(nextLineItem).length !== 0) {
    checkEditValue(nextLineItem);
  } else {
    setFocusOnAdd();
  }
};

export const setFocusOnRemove = (lineItem: Beneficiary) => {
  const removeButton: HTMLElement = document.querySelector(`[id=${lineItem.removeId}]`);
  if (removeButton) {
    requestAnimationFrame(() => {
      removeButton.focus();
    });
  }
};

export const setFocusOnAddEdit = (isAddMode: boolean, focusEditButton: Beneficiary) => {
  if (isAddMode) {
    setFocusOnAdd();
  } else {
    checkEditValue(focusEditButton);
  }
};

export const generateEditRemoveIdValue = (beneficiariesResponse: BeneficiariesResponse) => {
  let beneficiariesList = null;
  if (beneficiariesResponse) {
    beneficiariesList = generateEditRemoveId(beneficiariesResponse.beneficiaries, 'beneficiaryId');
  }
  return beneficiariesList;
};

export const displayBeneficiariesName = (name) => {
  const { first, middle, last } = name || {};
  // Remove double spaces in middle if no middle name and removes all-caps in favor of CSS
  return name ? `${first} ${middle} ${last}`.replace(/\s+/g, ' ').toLowerCase() : '';
};
