import React, { useState } from 'react';
import Button from 'bank-component-library/ui/atoms/Button';
import { Grid } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { Link } from 'react-router-dom';
import { useEffectOnce } from '../../utilities/reactHooks';
import { RadioField } from '../finalForm/finalFormInputs';
import { requiredField } from '../../utilities/validators';
import useOtpStyles from './transmitOtp.styles';
import type { SendOtpType } from '../../containers/security/security.service';
import IgniteFlashMessage from '../flashMessage/IgniteFlashMessage';
import { FlashMessageVariant } from '../flashMessage/flashMessage.constants';
import i18n from '../../strings/i18n';
import ConfirmationModal from '../modal/ConfirmationModal';
import { SynchronyInfo } from '../cms/blockText.constants';
import { FormHeaderProps } from '../formWithImage/typings';
import pageTrack from '../../analytics/pageAnalytics.constants';
import pageAnalytics from '../../analytics/pageAnalytics';
import BackButtonWarning from './BackButtonWarning';

type AllProps = SendOtpType & FormHeaderProps;

function SendOtp(props: AllProps) {
  const {
    targets,
    localizedContent,
    isSendAsVoiceEnabled,
    showErrorContainer,
    showErrorMessage,
    analyticsConstants,
    handleSendOtp,
    handleCancel,
    handleDontSeeNumber,
    handleSendCodeAs,
  } = props;

  const radioOptions = [
    {
      label: i18n({ OTPRadioText: 'SEND_CODE_TEXT_LABEL' }),
      secondaryLabel: i18n({ OTPRadioText: 'SEND_CODE_TEXT_DESCRIPTION' }),
      value: i18n({ OTPRadioText: 'SEND_CODE_TEXT_VALUE' }),
    },
    {
      label: i18n({ OTPRadioText: 'SEND_CODE_CALL_LABEL' }),
      value: i18n({ OTPRadioText: 'SEND_CODE_CALL_VALUE' }),
    },
  ];

  const classes = useOtpStyles(props);
  const [showModal, setShowModal] = useState(false);

  const analyticData = analyticsConstants;

  const { headerSubtitle } = localizedContent.sendCodeScreen;
  const headerSubtitleInto = headerSubtitle.intro;
  const htmlSendCodeHeaderSubtitleOtp = headerSubtitle.oneTimePasscode;
  let htmlSendCodeHeaderSubtitle = '';
  const singlePhoneNumberExists = targets !== '';
  const { value } = radioOptions[0];
  handleSendCodeAs(value);
  const [sendCodeSelection, setSendCodeSelection] = useState(value);
  const modalTitle = i18n({ OTPValidateText: 'OTP_VALIDATE_HEADER_CANCEL' }).toLowerCase();
  const modalBody = i18n({ OTPValidateText: 'OTP_VALIDATE_BODY_CANCEL' }).toLowerCase();

  if (isSendAsVoiceEnabled) {
    if (singlePhoneNumberExists) {
      htmlSendCodeHeaderSubtitle += headerSubtitle.singlePhoneNumber;
    } else if (singlePhoneNumberExists !== true) {
      htmlSendCodeHeaderSubtitle += headerSubtitle.multiplePhoneNumbers;
    }
  } else {
    htmlSendCodeHeaderSubtitle = headerSubtitle.onlySendAsSmsTextOptionAvailable;
  }

  const handleFormDontSeeNumber = (e: Event | React.SyntheticEvent<Element, Event>) => {
    e.preventDefault();
    handleDontSeeNumber();
  };

  function handleFormSendOTP(e: Event | React.SyntheticEvent<Element, Event>) {
    e.preventDefault();
    handleSendOtp();
  }

  const onSubmit = (e: Event | React.SyntheticEvent<Element, Event>) => {
    e.preventDefault();
  };

  useEffectOnce(() => {
    // eslint-disable-next-line no-underscore-dangle
    if (window?._SFDDL?.pageInfo?.pagename !== pageTrack.pagename.otp_text_or_call) {
      return pageAnalytics
        .newPageView({
          account_type: pageTrack.account_type.generic,
          pagekind: pageTrack.pagekind.account_opening,
          pagename: pageTrack.pagename.otp_text_or_call,
          pagefunction: pageTrack.pagefunction.ao_otp,
          pagesubfunction: pageTrack.pagesubfunction.NAO,
        })
        .record();
    }
    return null;
  });

  return (
    <div>
      {showErrorContainer === true && (
        <IgniteFlashMessage variant={FlashMessageVariant.ERROR}>
          {showErrorMessage}
        </IgniteFlashMessage>
      )}
      <ConfirmationModal
        confirmText={i18n({ ModalBtnText: 'EXIT_BTN' })}
        exitText={i18n({ ModalBtnText: 'CANCEL_CONTINUE_BTN' })}
        contentText={i18n({ ModalText: 'CANCEL_TRANSMIT_BODY' }, { modalBody })}
        data-test="cancel-modal"
        onExit={() => {
          setShowModal(false);
        }}
        onConfirm={handleCancel}
        titleText={i18n({ ModalText: 'CANCEL_TRANSMIT_TITLE' }, { modalTitle })}
        visible={showModal}
      />

      <header data-test="send-otp-holder">
        <Grid
          data-test="send-otp-header"
          item
          component="div"
          xs={12}
          className={classes.headerTitle}
        >
          {i18n({ OTPValidateText: 'OTP_VALIDATE_HEADER_TRANSMIT' })}
        </Grid>

        {singlePhoneNumberExists && (
          <Grid
            item
            component="div"
            data-test="single-phone-number-exists"
            xs={12}
            className={classes.headerDescription}
          >
            {isSendAsVoiceEnabled ? (
              <span>
                {headerSubtitleInto}
                <strong>{htmlSendCodeHeaderSubtitleOtp}</strong>
                {htmlSendCodeHeaderSubtitle}
                <strong>{targets.slice(-4)}</strong>
              </span>
            ) : (
              <>
                <span>{htmlSendCodeHeaderSubtitle}</span>
                <strong>{targets.slice(-4)}</strong>
              </>
            )}
          </Grid>
        )}

        {singlePhoneNumberExists !== true && (
          <span>
            {isSendAsVoiceEnabled === true && (
              <span>
                {headerSubtitleInto}
                <strong>{htmlSendCodeHeaderSubtitleOtp}</strong>
                {htmlSendCodeHeaderSubtitle}
              </span>
            )}

            {isSendAsVoiceEnabled !== true && <span>{htmlSendCodeHeaderSubtitle}</span>}
          </span>
        )}

        <BackButtonWarning warningClass={classes.headerSubDescription} />

        <Grid item component="div" xs={12} className={classes.otpLinksHolder}>
          <Link
            className={classes.otpLinks}
            title={analyticData.dontSeeYourNumber.title}
            data-type={analyticData.dontSeeYourNumber.dataType}
            data-object={analyticData.dontSeeYourNumber.dataObject}
            data-reason={analyticData.dontSeeYourNumber.dataReason}
            onClick={handleFormDontSeeNumber}
            to="/"
          >
            {i18n({ OTPValidateText: 'NOT_YOUR_PHONE_NUMBER' })}
          </Link>
        </Grid>
        <Grid
          item
          data-test="enter-phone-number-header"
          component="div"
          xs={12}
          className={classes.headerSubDescription}
        >
          {i18n({ OTPText: 'OTP_TRUSTSCORE_NO_PHONE_TEXT' })}
          {SynchronyInfo.OTP_PHONE_LINK}.
        </Grid>
      </header>

      <div className={classes.sendOtpContainer}>
        {isSendAsVoiceEnabled === true && (
          <div id="sendCodeAsListTransmit">
            <Form data-test="send-otp-form" onSubmit={onSubmit}>
              {() => {
                return (
                  <>
                    <Field
                      type="radio"
                      name={i18n({ sendOTPFieldName: 'SEND_CODE_AS' })}
                      component={RadioField}
                      validate={requiredField()}
                      options={radioOptions}
                      selectedValue={sendCodeSelection}
                      ariaLabelledBy="send-otp-code-group-label"
                    />
                    <OnChange name={i18n({ sendOTPFieldName: 'SEND_CODE_AS' })}>
                      {(optValue) => {
                        setSendCodeSelection(optValue);
                        handleSendCodeAs(optValue);
                      }}
                    </OnChange>
                  </>
                );
              }}
            </Form>
          </div>
        )}
      </div>
      <Grid container spacing={2} className={classes.buttonHolderCL}>
        <Grid item xs={5}>
          <Button
            id="confirmBtn"
            variant="outlined"
            data-test="cancel-otp-selection"
            className={classes.authenticateBtn}
            onClick={() => setShowModal(true)}
          >
            {localizedContent.sendCodeScreen.cancel}
          </Button>
        </Grid>
        <Grid item xs={5}>
          <Button
            // eslint-disable-next-line react/jsx-no-bind
            onClick={handleFormSendOTP}
            id="sendOTPButton"
            data-test="continue-send-otp"
            data-type={analyticData.continue.dataType}
            data-object={analyticData.continue.dataObject}
            data-reason={analyticData.continue.dataReason}
            className={classes.authenticateBtn}
          >
            {localizedContent.sendCodeScreen.continue}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default SendOtp;
