import apptimize from '@apptimize/apptimize-web-sdk';
import { DynamicVariables, FeatureFlags } from './apptimize.typings';

/**
 * Initializes Apptimize
 * @param onInitCallback Optional callback that runs when Apptimize
 * is finished initializing.
 */
export const initApptimize = (onInitCallback?: () => void) => {
  apptimize.setOnApptimizeInitializedCallback(onInitCallback);
  apptimize.setup(window.__config__.APPTIMIZE_KEY);
};

/**
 * Retrieve feature flag value
 * @param flagName Name of feature flag in Apptimize
 * @returns `true` if flag enabled, `false` if disabled or not found
 */
export const isFeatureFlagEnabled = (flagName: FeatureFlags): boolean =>
  apptimize.isFeatureFlagEnabled(flagName);

/**
 * Retrieve Dynamic string
 * @param dynamicVariableName Name of the dynamic variable in Apptimize
 * @param defaultValue Optional. Default value for the 'original' variant in Apptimize.
 * @returns Dynamic string value
 */
export const getDynamicString = (
  dynamicVariableName: DynamicVariables,
  defaultValue = ''
): string => apptimize.getString(dynamicVariableName, defaultValue);
