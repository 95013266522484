import React from 'react';
import { InlineLinkText } from '../typography/typography';
import Colors from '../colors/colors';
import { boldFontWeight } from '../typography/typography.styles';

const phoneStyle = { whiteSpace: 'nowrap' };
const phoneStyleDark = {
  ...phoneStyle,
  color: Colors.charcoalGrey,
  fontWeight: boldFontWeight,
};
const PhoneLink = () => (
  <InlineLinkText href="tel://18662265638" style={phoneStyle} data-test="phone-link">
    1-866-226-5638
  </InlineLinkText>
);
const CDPhoneLink = () => (
  <InlineLinkText href="tel://18443455790" style={phoneStyle}>
    1-844-345-5790
  </InlineLinkText>
);
const CDPhoneLinkDark = () => (
  <InlineLinkText href="tel://18443455790" style={phoneStyleDark}>
    1-844-345-5790
  </InlineLinkText>
);
const OtpPhoneLink = () => (
  <InlineLinkText href="tel://18448225915" style={phoneStyle}>
    1-844-822-5915
  </InlineLinkText>
);
const MembersLink = () => (
  <InlineLinkText href="https://www.synchronybank.com/members">
    www.synchronybank.com/members
  </InlineLinkText>
);

// when other exports are declared this eslint can be removed
// eslint-disable-next-line
export const SynchronyInfo = Object.freeze({
  CD_PHONE: <CDPhoneLink />,
  CD_PHONE_DARK: <CDPhoneLinkDark />,
  CD_PHONE_TEXT: '1-844-345-5790',
  PHONE: <PhoneLink />,
  PHONE_TEXT: '1-866-226-5638',
  OTP_PHONE_LINK: <OtpPhoneLink />,
  OTP_PHONE_TEXT: '1-844-822-5915',
  AUTH_PHONE_TEXT: '1-844-822-5915',
  BANKNAME: 'Synchrony Bank',
  ADDRESS_POBOX: 'P.O. Box 105972',
  ADDRESS_CITY: 'Atlanta',
  ADDRESS_STATE: 'GA',
  ADDRESS_STATE_FULL: 'Georgia',
  ADDRESS_ZIP: '30348-5972',
  ADA_POLICY: (
    <>
      You authorize Synchrony Bank (and any other servicer of your account) to contact you about
      your account, including using any contact information or mobile phone numbers you have
      provided above. You also consent to the use of text messages, any automatic telephone dialing
      system and/or artificial or prerecorded voice when contacting you, even if you are charged for
      the message or call under your phone plan. Standard text messaging rates may apply.
    </>
  ),
});

export const ModalText = Object.freeze({
  ACTIVITY_MODAL_BODY:
    'For your security, you will be signed out in 1 minute due to inactivity. Click the button below if you would like to stay signed in.',
  ACTIVITY_MODAL_TITLE: 'Are you still there?',
  AO_ACTIVITY_MODAL_BODY:
    'For your security, this account opening application will end in 1 minute due to inactivity and you will not be able to access this application again. To continue opening an account, select Continue below.',
  NO_PHONE_NUMBER_BODY:
    'We do not have your phone number on file. To continue with opening a new account please enter a valid phone number.',
  NO_PHONE_NUMBER_TITLE: 'No phone number on file',
  CANCEL_ADD_NEW_EXTERNAL_ACCOUNT_BODY:
    'Are you sure you want to exit and cancel adding a new external account? You’ll have to start over next time.',
  CANCEL_ADD_NEW_EXTERNAL_ACCOUNT_TITLE: 'Cancel adding a new external account?',
  CANCEL_ADD_EXTERNAL_ACCOUNT_BODY:
    'Are you sure you want to cancel adding this external account and leave this page?',
  CANCEL_ADD_EXTERNAL_ACCOUNT_TITLE: 'Cancel adding external account',
  CANCEL_APPLICATION_BODY:
    'Your application will not be saved and the information you entered will be lost. Are you sure you want to leave the application?',
  CANCEL_APPLICATION_TITLE: 'Cancel and exit application?',
  CANCEL_CD_RENEWAL_TITLE: 'Cancel adding instructions',
  CANCEL_CD_RENEWAL_BODY:
    'Are you sure you want to leave this page? Your instructions will not be saved and the information you entered will be lost. If you do not submit renewal instructions, your CD will automatically renew at the same term at the APY and interest rate in effect on the maturity date.',
  CANCEL_CARD_MODAL_TITLE: 'Cancel Card?',
  CANCEL_CARD_MODAL_BODY:
    'Are you sure you wish to proceed with canceling your card? This action cannot be undone.',
  CANCEL_FUNDING_TITLE: 'Cancel and exit funding?',
  CANCEL_FUNDING_BODY:
    'Are you sure you want to leave? You may return at any time to add funds to your account. Please remember, your account will be closed if it is not funded within 60 days.',
  CANCEL_OTP_VERIFICATION_TITLE: 'Cancel Verification',
  CANCEL_OTP_VERIFICATION_TITLE_NEW_ACCOUNT: 'Cancel confirming identity?',
  CANCEL_OTP_VERIFICATION_BODY:
    'Are you sure you want to leave without submitting a verification code? Please note, you will not be able to make changes to your username or password without this verification.',
  CANCEL_TRANSFER_TITLE: 'Cancel transfer',
  CANCEL_OTP_VERIFICATION_BODY_NEW_ACCOUNT:
    'You will not be able to complete the account opening process on the website and will need to call a Banker to proceed. Are you sure you want to cancel confirming your identity?',
  CANCEL_RECURRING_TRANSFER_BODY: 'Are you sure you want to cancel this recurring transfer?',
  CANCEL_SCHEDULED_TRANSFER_BODY: 'Are you sure you want to cancel this scheduled transfer?',
  CANCEL_NEW_TRANSFER_BODY:
    'Are you sure you want to cancel this transfer and leave this page? Please note, this will cancel any transfers that are currently in process.',
  CANCEL_VERIFY_TRIAL_DEPOSITS_TITLE: 'Cancel verifying trial deposits',
  CANCEL_VERIFY_TRIAL_DEPOSITS_BODY:
    'Are you sure you want to cancel verifying these trial deposits and leave this page?',
  CD_RENEWAL_ADD_EXTERNAL_ACCOUNT_TITLE: 'Exit and go to add external account?',
  CD_RENEWAL_ADD_EXTERNAL_ACCOUNT_BODY:
    'By clicking below, you can continue to try to add a new external account. Once you have added the external account, you may return to submit your CD renewal instructions. Any instructions you&apos;ve entered so far will not be saved.',
  CD_INTEREST_PAYMENTS_ADD_EXTERNAL_ACCOUNT_TITLE: 'Exit and go to add external account?',
  CD_INTEREST_PAYMENTS_ADD_EXTERNAL_ACCOUNT_BODY:
    'By clicking below, you can continue to try to add a new external account. Once you have added the external account, you may return to continue setting up monthly interest payments for your CD.',
  EDIT_BENEFICIARY_MODAL_TITLE: 'Edit Beneficiary',
  ADD_BENEFICIARY_MODAL_TITLE: 'Add New Beneficiary',
  INVALID_SPECIAL_MEMBER_CODE_TITLE: 'Invalid Special Member Code',
  INVALID_SPECIAL_MEMBER_CODE_BODY: (
    <>
      The code you have entered for the special member rates is invalid or has expired. Please
      return to <MembersLink /> and try again or call {SynchronyInfo.PHONE}
    </>
  ),
  LEAVING_ETIN_TITLE: 'Thank you!',
  LEAVING_ETIN_BODY: 'Synchrony Bank may reach out to you if there are any other questions.',
  LEAVING_SITE_WARNING_MODAL_TITLE: 'You are leaving Synchrony Bank',
  LEAVING_SITE_WARNING_MODAL_BODY:
    'Please note that because the site you are going to is not controlled by Synchrony Bank, you will be subject to the terms of use and privacy policy of the website you are visiting.',
  ORDER_CHECKS_LEAVING_SITE_MODAL_BODY:
    'Please note that to complete this transaction, you’ll be going to our check vendor’s site, and will be subject to the terms of use and privacy policy of that site. When ready, click on Begin Order to proceed. For your protection, you may be required to log back in when you return to our site.',
  REMOVE_BENEFICIARY_MODAL_TITLE: 'Remove Beneficiary',
  REMOVE_BENEFICIARY_MODAL_BODY:
    'Are you sure you want to remove this beneficiary? This will remove them from all accounts.',
  REVERT_CHANGES_MODAL_TITLE: 'Revert Changes',
  REVERT_CHANGES_MODAL_BODY:
    'Are you sure you want to revert changes? Your updated selections have not yet been saved.',
  REVERT_TRANSFER_CHANGES_MODAL_BODY:
    'Are you sure you want to cancel making changes to this transfer and leave this page? Please note, your changes will not be saved.',
  TRY_AGAIN_MODAL_TITLE: 'An error has occurred.',
  TRY_AGAIN_MODAL_BODY: <>Please try again or contact a Banker at {SynchronyInfo.PHONE}.</>,
  CANCEL_ALERT_MODAL_TITLE: 'Cancel Changes',
  CANCEL_ALERT_MODAL_CONTENT:
    'Are you sure you want to exit edit mode? Please note, any changes you have made will not be saved.',
  CANCEL_ACCOUNT_SELECTION_MODAL_TITLE: 'Cancel Account Selection?',
  CANCEL_ACCOUNT_SELECTION_MODAL_CONTENT:
    'Are you sure you wish to cancel setting up recurring interest payments? Your selections will not be saved.',
  INTEREST_PAYMENTS_CHECK_TITLE: 'Change Interest Payments to an account?',
  INTEREST_PAYMENTS_CHECK_BODY:
    'You are currently receiving interest payments by check. If you change this delivery method, you will need to contact a Banker to restore your current settings. Are you sure you want to proceed?',
  STOP_RECURRING_INTEREST_PAYMENTS_TITLE: 'Stop Recurring Interest Payments',
  STOP_RECURRING_INTEREST_PAYMENTS_CONTENT:
    'Are you sure you want to stop incurring interest payments? Your next interest payment and any interest paid after will be posted to your CD.',

  CANCEL_MESSAGE_MODAL_TITLE: 'Cancel Composing Message?',
  CANCEL_MESSAGE_MODAL_CONTENT:
    'Are you sure you want to cancel composing this message? A draft of this message has not been saved.',
  GENERIC_ERROR_MODAL_TITLE: 'Something went wrong',
  COMBINED_STATEMENTS_MODAL_TITLE: 'Combined Statements',
  COMBINED_STATEMENTS_MODAL_BODY:
    'For your convenience, we offer combined statements for your accounts.* If you wish to receive separate statements for each of your accounts, you can change your preferences below.',
  COMBINED_STATEMENTS_OPTED_OUT_MODAL_BODY: (
    <>
      You are currently opted out of combined statements for your accounts.* If you’d like to opt in
      to combined statements, you will need to call a Banker at {SynchronyInfo.PHONE}.
    </>
  ),
  COMBINED_STATEMENTS_OPTED_OUT_MODAL_FOOTNOTE:
    '*You can only change preferences for accounts that are solely owned by you. Statements for any IRA or jointly-owned accounts will be provided separately.',
  COMBINED_STATEMENTS_MODAL_OPTED_IN_MODAL_FOOTNOTE:
    '*A combined statement will include all accounts that are solely owned by you. Statements for any IRA or jointly-owned accounts will continue to be provided separately.',
  COMBINED_STATEMENTS_OPT_BACK_IN_FOOTNOTE:
    'If you decide that you’d like to opt back in to combined statements, you will need to contact a Banker.',
  ATM_LOCATOR_WARNING_MODAL_BODY:
    'Please note that because the site you are going to is not controlled by Synchrony Bank, you will be subject to the terms of use and privacy policy of the website you are visiting.',
  EXIT_MODAL_CONTENT:
    'Are you sure you want to exit? Your updated selections have not yet been saved.',
  NON_PROMITIONAL_PRODUCT_MODAL_TITLE:
    'The product you’ve selected is not eligible for the promo rate.',
  NON_PROMITIONAL_PRODUCT_MODAL_CONTENT:
    'If you want to maintain the promo, go back and select the promotional product. Or, you can continue with the product you selected.',
  POPUP_BLOCKED_MODAL_TITLE: 'A pop-up blocker is preventing your documents from opening.',
  POPUP_BLOCKED_MODAL_BODY:
    'It looks like an ad or pop-up blocker is preventing you from viewing your documents. Please check to see if you have an ad or pop-up blocker and disable it for this site to view your documents.',
  PROMO_FUNDING_CONFIRMATION_TITLE: 'Is your deposit amount correct?',
  PROMO_FUNDING_CONFIRMATION_TEXT_LINE_1: 'The minimum deposit required for this promotion is',
  PROMO_FUNDING_CONFIRMATION_TEXT_LINE_2:
    'If you proceed, your deposit does not meet the minimum amount required to receive the promotional offer.',
  CANCEL_PROMO_FUNDING_BODY:
    'Are you sure you want to leave? You will lose your promotional benefits if you don’t fund your account in time.',
});
export const NO_EMAIL_ON_FILE =
  'It looks like we don’t have an email on file. To manage these settings, you’ll need to edit your profile.';

export const ATTACHMENT_LIMIT = (
  <span id="add-attachments-limit-message">
    You can upload up to {window.__config__.MESSAGE_ATTACHMENT_LIMIT} attachments to a message. The
    file size limit is 5MB.
  </span>
);
