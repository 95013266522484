import { StyleRules } from '@material-ui/core';
import pxToRem from '../../utilities/pxToRem';
import Colors from '../colors/colors';
import * as alignmentStyles from '../../styles/layout/alignment.styles';
import breakpoint from '../../styles/breakpoints';
import * as Z_INDEX from '../../styles/zIndex';
import type { Theme } from '../../utilities/types';

export const headerBottomBoxShadow = `${pxToRem(8)} ${pxToRem(2)} ${pxToRem(
  12
)} 0 rgba(0, 0, 0, 0.5)`;

export const headerHeight = {
  collapsed: pxToRem(100),
  expanded: {
    md: pxToRem(160),
    lg: pxToRem(200),
  },
} as const;

const styles = (theme: Theme): StyleRules => ({
  headerWithBackgroundAndLogo: {
    height: pxToRem(192),
    backgroundColor: Colors.darkBlue,

    [theme.breakpoints.up('sm')]: {
      height: pxToRem(172),
    },
    [theme.breakpoints.up('md')]: {
      height: pxToRem(176),
    },
    [theme.breakpoints.up('lg')]: {
      height: pxToRem(200),
    },
    '& $skipLink': {
      color: `${Colors.white} !important`,
    },
    '&:not($headerClose) $skipLink': {
      color: `${Colors.text} !important`,
    },
  },
  headerWithBackground: {
    backgroundColor: Colors.darkBlue,
    height: headerHeight.expanded.lg,
    [theme.breakpoints.down('md')]: {
      height: headerHeight.expanded.md,
    },
    width: '100%',

    '& $skipLink': {
      color: `${Colors.white} !important`,
    },
    '&:not($headerClose) $skipLink': {
      color: `${Colors.text} !important`,
    },
  },
  headerWithoutBackground: {
    transition: 'background-color 1s ease-in-out',
    backgroundColor: 'white',
    '& $skipLink': {
      color: `${Colors.text} !important`,
    },
  },
  appBar: {
    transition: 'max-height .2s, height 0.2s',
    maxHeight: '100%',

    display: 'flex',
    justifyContent: 'stretch',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [`${theme.breakpoints.down('md')}, (hover: none)`]: {
      display: 'block',
    },

    boxShadow: 'none',
  },

  toolbar: {
    ...alignmentStyles.Alignment.horizontalPadding,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: Z_INDEX.Z_INDEX_HEADER_INTERACTABLE,
    width: 'auto',
    height: headerHeight.collapsed,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  itemsHoverDeactive: {
    [`${theme.breakpoints.down('md')}, (hover: none)`]: {
      width: '100%',
    },
  },

  mobileHidden: {
    display: 'block',
    [`${theme.breakpoints.down('md')}, (hover: none)`]: {
      display: 'none',
    },
  },
  mobileVisible: {
    display: 'none',
    marginRight: pxToRem(-12),
    [`${theme.breakpoints.down('md')}, (hover: none)`]: {
      display: 'block',
    },
  },
  focusDarkThemeMenu: {
    '&:focus': {
      outline: `${pxToRem(2)} solid ${Colors.transmitPrimaryBtnText}`,
    },
  },
  focusLightThemeMenu: {
    '&:focus': {
      outline: `${pxToRem(2)} solid ${Colors.optimumBlue}`,
    },
  },
  menuHit: {
    width: '100%',
    height: '4.5rem',
    position: 'fixed',
    left: pxToRem(250),
  },
  menuExit: {
    width: '100%',
    height: '100%',
    position: 'fixed',
  },
  collapseSection: {
    position: 'absolute',
    top: 0,
    left: 0,
    overflow: 'visible',
    zIndex: Z_INDEX.Z_INDEX_HEADER_COLLAPSE,
    backgroundColor: 'white',
    width: '100%',
    paddingRight: pxToRem(4),
    boxShadow: headerBottomBoxShadow,
    visibility: 'visible',
    [`${theme.breakpoints.down('md')}, (hover: none)`]: {
      overflow: 'hidden',
    },
  },
  items: {
    display: 'flex',
    justifyContent: 'space-between',
    flexGrow: 1,
    paddingLeft: pxToRem(248),
    paddingTop: pxToRem(30),
    paddingBottom: pxToRem(35),
    overflow: 'hidden',
  },
  itemsNotCached: {
    [`${theme.breakpoints.down('md')}, (hover: none)`]: {
      paddingLeft: pxToRem(16),
      justifyContent: 'flex-start',
      paddingTop: pxToRem(110),
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingBottom: pxToRem(75),
    },
  },
  headerPageContainer: {
    ...alignmentStyles.Alignment.horizontalPadding,
    position: 'absolute',
    zIndex: Z_INDEX.Z_INDEX_HEADER_TITLE,
    width: '100%',
    overflow: 'hidden',
    top: headerHeight.collapsed,
  },
  headerPageContainerWithLogo: {
    ...alignmentStyles.Alignment.horizontalPadding,
    position: 'absolute',
    zIndex: Z_INDEX.Z_INDEX_HEADER_TITLE,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: pxToRem(8),
    top: pxToRem(80),
    [theme.breakpoints.up('md')]: {
      top: pxToRem(88),
      gap: pxToRem(12),
    },
  },
  headerPageTitle: {
    color: 'white',
    fontWeight: 700,
    fontSize: pxToRem(30),
    transition: 'font-size 0.2s, top 0.2s, width 0.1s step-end, opacity 0.1s',
    width: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.up('lg')]: {
      fontSize: pxToRem(50),
    },
  },

  headerPortal: {
    position: 'absolute',
    top: headerHeight.expanded.md,
    width: '100%',
    transition: 'top 0.2s',
    [theme.breakpoints.up('lg')]: {
      top: headerHeight.expanded.lg,
    },
  },

  // transition states
  headerMenuOpen: {
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      overflow: 'auto',
    },
    maxHeight: '100vh',
  },
  headerMenuOpened: {
    overflow: 'auto',
  },

  headerClose: {
    overflow: 'visible',
  },

  toolbarOpened: {
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'white',
    },
  },
  disableHover: {
    pointerEvents: 'none',
  },
  headerPageContainerCollapsed: {
    opacity: 0,
    pointerEvents: 'none',
    [`${theme.breakpoints.down('md')}, (hover: none)`]: {
      width: `calc(100% - ${pxToRem(40)})`,
      fontSize: pxToRem(30),
      // The page title is 33px tall, this centers it within a 100px tall container
      top: pxToRem(33.5),
      transition: 'font-size 0.2s, top 0.2s, width 0.1s step-start, opacity 0.1s',
      opacity: 1,
    },
  },
  headerPageContainerWithLogoCollapsed: {
    pointerEvents: 'none',
    top: pxToRem(32),
    fontSize: pxToRem(36),
    transition: 'font-size 0.2s, top 0.2s, width 0.1s step-start, opacity 0.1s',
    opacity: 1,
    [theme.breakpoints.up('sm')]: {
      top: pxToRem(44),
    },
  },
  headerCollapsed: {
    height: headerHeight.collapsed,
  },
  headerCollapsedWithLogo: {
    height: pxToRem(132),
    [theme.breakpoints.up('lg')]: {
      height: pxToRem(144),
    },
  },
  headerPortalCollapsed: {
    top: headerHeight.collapsed,
  },
  logo: {
    transition: 'opacity 0.2s',
  },
  logoHidden: {
    transition: 'opacity 0.2s',
    [`${theme.breakpoints.down('md')}, (hover: none)`]: {
      opacity: 0,
    },
  },
  skipLink: {
    borderRadius: pxToRem(2),
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    fontWeight: 800,
    padding: `0 ${pxToRem(2)}`,
    top: pxToRem(2),
    left: alignmentStyles.defaultPadding,
    [breakpoint.up('sm')]: {
      left: alignmentStyles.smallPadding,
    },
    [breakpoint.up('md')]: {
      left: alignmentStyles.mediumPadding,
    },
    [breakpoint.up('lg')]: {
      left: alignmentStyles.largePadding,
    },
  },
  navClosed: {
    height: headerHeight.collapsed,
    overflow: 'hidden',
  },
  navOpen: {
    overflow: 'hidden',
  },
});

export default styles;
