import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  withStyles,
  FormGroup,
  InputAdornment,
} from '@material-ui/core';
import * as React from 'react';
import type { FieldProps } from 'redux-form/es/index';
import { bclTwMerge } from 'bank-component-library';
import type { FieldRenderProps } from 'react-final-form';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import {
  Checkbox,
  Select as SelectBCL,
  TextField as TextFieldBCL,
} from 'bank-component-library/ui/atoms';
import { WrappedFieldMetaProps } from 'redux-form';
import type { StyleProps } from '../../utilities/types';
import type { Term } from '../../containers/products/products.reducer';
import ErrorWrapper from '../../containers/newAccountOpening/errorWrapper/errorWrapper';
import type { CheckProps } from '../inputs/inputs';
import TextField, {
  BirthDateField,
  CurrencyField,
  EmailField,
  PhoneNumberField,
  SSNField,
  ZipcodeField,
} from '../inputs/inputs';
import Select from '../inputs/select';
import { SelectField } from '../cms/inputText.constants';
import { InlineLinkText } from '../typography/typography';
import Routes from '../../containers/routes/routes.constants';
import { clickTrack } from '../../analytics/clickTracking.constants';
import {
  Checkbox as MUIFinalFormCheckbox,
  useCheckboxStyles,
  useNoPhoneCheckStyles,
  useRadioStyles,
  currencyFieldStyles,
  checkboxFormInputBCL,
} from '../finalForm/finalFormInputs.styles';
import i18n from '../../strings/i18n';
import ImagesFileNames from '../../images';
import SVGImage from '../svgImage';
import { CheckboxCheckedIconSVG } from '../svg/svg';
import { CheckboxFormErrorBCL, SelectOptionBCL } from '../finalForm/finalFormInputs';

export type ReduxFieldProps = {
  ['data-test']?: string;
  id: string;
  label: string;
  labelId?: string;
  labelClass: any;
  fieldsetClass: any | null | undefined;
  helperText: string;
  disabled?: boolean;
  ariaRequired?: boolean;
  ariaDescribed?: string;
  required?: boolean;
  checked?: boolean;
  meta?: WrappedFieldMetaProps;
} & FieldProps;

export const RenderEmailField = ({
  id,
  input,
  label,
  labelId,
  ariaRequired,
  ariaDescribed,
  meta: { touched, active, error },
  ...rest
}: ReduxFieldProps) => (
  <EmailField
    {...input}
    {...rest}
    id={id}
    placeholder={label}
    label={label}
    labelId={labelId}
    error={touched && !active && !!error}
    helperText={touched && !active && error ? error : ''}
    aria-invalid={touched && !active && !!error}
    ariaRequired
    ariaDescribed={ariaDescribed}
    FormHelperTextProps={{ error: true, component: ErrorWrapper }}
  />
);

type TextFieldProps = {
  required?: boolean;
  placeholder: string;
  FormHelperTextProps:
    | {
        ariaLive?: string;
      }
    | any;
  helperText: string;
  maxLength?: number;
};

export const RenderTextField = ({
  input,
  label,
  labelId,
  required,
  placeholder,
  helperText = '',
  'data-test': dataTest,
  ariaRequired,
  FormHelperTextProps = {},
  meta: { touched, active, error, submitError },
  ...rest
}: ReduxFieldProps & {
  // Combining the types for meta from redux-form and react-final-form
  meta: ReduxFieldProps['meta'] & FieldRenderProps<'meta'>;
} & TextFieldProps) => {
  const errorMessage = error || submitError;
  let formHelperTextProps;
  if (touched && !active && errorMessage) {
    formHelperTextProps = { error: true, component: ErrorWrapper };
  } else {
    formHelperTextProps = FormHelperTextProps;
  }
  return (
    <TextField
      {...input}
      {...rest}
      labelId={labelId}
      label={label}
      data-test={dataTest}
      placeholder={placeholder || label}
      helperText={touched && !active && errorMessage ? errorMessage : helperText}
      error={touched && !active && !!errorMessage}
      aria-invalid={touched && !!errorMessage}
      ariaRequired={required || ariaRequired}
      FormHelperTextProps={formHelperTextProps}
    />
  );
};

export const RenderTextFieldBCL = (
  props: ReduxFieldProps & {
    // Combining the types for meta from redux-form and react-final-form
    meta: ReduxFieldProps['meta'] & FieldRenderProps<'meta'>;
  } & TextFieldProps
) => {
  const {
    id,
    input,
    label,
    required,
    placeholder,
    helperText = '',
    'data-test': dataTest,
    ariaRequired,
    meta: { touched, error },
    ...rest
  } = props;
  const { onChange, name, onBlur, onFocus, value, type = 'text' } = input;
  const hasError = touched && !!error;

  return (
    <TextFieldBCL
      {...rest}
      id={id}
      onChange={onChange}
      label={label}
      helperIcon={helperText}
      errorText={hasError && error}
      placeholder={placeholder}
      required={required}
      name={name}
      type={type}
      onBlur={onBlur}
      onFocus={onFocus}
      value={value}
      data-test={dataTest}
      className="align-top"
    />
  );
};

const preventDefaultOnEvent = (e) => e.preventDefault();

export const RenderSSNField = ({
  id,
  input,
  label,
  ariaRequired,
  placeholder,
  helperText = '',
  meta: { touched, active, error },
  ...rest
}: ReduxFieldProps & TextFieldProps) => (
  <SSNField
    {...input}
    {...rest}
    id={id}
    label={label}
    onCopy={preventDefaultOnEvent}
    onCut={preventDefaultOnEvent}
    onPaste={preventDefaultOnEvent}
    onContextMenu={preventDefaultOnEvent}
    helperText={touched && !active && error ? error : helperText}
    error={touched && !active && !!error}
    aria-invalid={touched && !!error}
    FormHelperTextProps={{ error: touched && !active && !!error, component: ErrorWrapper }}
    ariaRequired
  />
);

export const renderCurrencyField = ({
  input,
  label,
  ariaRequired,
  labelId,
  placeholder,
  helperText = '',
  FormHelperTextProps = {},
  meta: { touched, error, active },
  ...rest
}: ReduxFieldProps & TextFieldProps) => {
  const classes = currencyFieldStyles();
  let formHelperTextProps;

  if (touched && !active && error) {
    formHelperTextProps = { error: true, component: ErrorWrapper };
  } else {
    formHelperTextProps = FormHelperTextProps;
  }

  return (
    <CurrencyField
      {...input}
      {...rest}
      shrink
      label={label}
      labelId={labelId}
      placeholder={placeholder || label}
      helperText={(touched && error) || helperText}
      error={touched && !!error}
      aria-invalid={touched && !!error}
      FormHelperTextProps={formHelperTextProps}
      ariaRequired
      InputAdornmentProps={
        error && (
          <InputAdornment position="end" className={classes.errorIconFocusStyle}>
            <SVGImage imageName={ImagesFileNames.iconErrorSvg} ariaHidden="true" />
          </InputAdornment>
        )
      }
    />
  );
};

export const RenderBirthDateField = ({
  id,
  input,
  label,
  labelId,
  ariaRequired,
  meta: { touched, active, error },
  helperText = '',
  ...rest
}: ReduxFieldProps) => (
  <BirthDateField
    {...input}
    {...rest}
    id={id}
    label={label}
    labelId={labelId}
    error={touched && !active && !!error}
    helperText={touched && !active && error ? error : helperText}
    aria-invalid={touched && !!error}
    FormHelperTextProps={{ error: touched && !active && !!error, component: ErrorWrapper }}
    ariaRequired
  />
);

export const RenderTelephoneField = ({
  id,
  input,
  label,
  labelId,
  ariaRequired,
  ariaDescribed,
  meta: { touched, active, error },
  ...rest
}: ReduxFieldProps) => (
  <PhoneNumberField
    {...input}
    {...rest}
    id={id}
    placeholder={label}
    label={label}
    labelId={labelId}
    error={touched && !active && !!error}
    helperText={touched && !active && error ? error : ''}
    aria-invalid={touched && !!error}
    FormHelperTextProps={{ error: true, component: ErrorWrapper }}
    ariaRequired={ariaRequired}
    ariaDescribed={ariaDescribed}
  />
);

type RenderPhoneFieldProps = {
  label?: string;
  labelId?: string;
  ['data-test']?: string;
  ariaRequired?: boolean;
  ariaDescribed?: string;
};

export const RenderPhoneTypeField = ({
  input,
  label,
  ariaRequired,
  ariaDescribed,
  'data-test': dataTest,
  meta: { touched, error },
  ...rest
}: FieldProps & RenderPhoneFieldProps): React.ReactElement<React.ComponentProps<typeof Select>> => (
  <Select
    {...input}
    {...rest}
    id="phoneType-select"
    label={label}
    error={touched && !!error}
    helperText={touched && error ? error : ''}
    aria-invalid={touched && !!error}
    inputProps={{
      'data-test': dataTest,
      'aria-required': ariaRequired,
      'aria-describedby': ariaDescribed,
    }}
  >
    {[
      <option key="mobile" value="Mobile">
        Mobile
      </option>,
      <option key="other" value="Other">
        Other
      </option>,
    ]}
  </Select>
);

export const RenderZipcodeField: React.ComponentType<any> = ({
  id,
  input,
  label,
  labelId,
  meta: { touched, active, error },
  ...rest
}: ReduxFieldProps) => (
  <ZipcodeField
    {...input}
    {...rest}
    id={id}
    placeholder={label}
    label={label}
    labelId={labelId}
    error={touched && !active && !!error}
    helperText={touched && !active && error ? error : ''}
    aria-invalid={touched && !!error}
    FormHelperTextProps={{ error: true, component: ErrorWrapper }}
  />
);

export type SelectFieldProps = {
  id: string;
  options: any[];
  placeholder?: string;
  disabled?: boolean;
};

export const RenderSelectField = ({
  id,
  label,
  labelId,
  ariaRequired,
  required,
  options,
  input,
  helperText,
  fieldinstruction,
  'data-test': dataTest,
  placeholder = SelectField.GENERIC,
  meta: { touched, active, error },
  ...rest
}: ReduxFieldProps & SelectFieldProps) => {
  let ariaDescribedProps = {};
  let ariaDescribed;
  ariaDescribed = id.concat(i18n({ inputHelperTextConstants: 'HELPER_TEXT_SUFFIX' }));
  if (fieldinstruction) {
    ariaDescribed = ariaDescribed.concat(' ', fieldinstruction);
  }
  ariaDescribedProps = { 'aria-describedby': ariaDescribed };
  return (
    <Select
      {...input}
      {...rest}
      id={id}
      label={label}
      labelId={labelId}
      data-test={dataTest}
      fullWidth
      error={touched && !active && !!error}
      helperText={touched && !active && error ? error : helperText}
      aria-invalid={touched && !!error}
      FormHelperTextProps={{
        error: touched && !active && !!error,
        id: `${id}${i18n({ inputHelperTextConstants: 'HELPER_TEXT_SUFFIX' })}`,
        component: ErrorWrapper,
      }}
      inputProps={{
        'data-test': dataTest,
        'aria-required': required || ariaRequired,
        ...ariaDescribedProps,
      }}
    >
      <option value="" disabled>
        {placeholder}
      </option>
      {options.map((option) => (
        <option
          key={option.value}
          value={option.value}
          disabled={option.disabled}
          aria-label={option.ariaLabel}
        >
          {option.name}
        </option>
      ))}
    </Select>
  );
};

export type SelectFieldBCLProps = {
  id: string;
  options: SelectOptionBCL[];
  placeholder?: string;
  disabled?: boolean;
};

export const RenderSelectFieldBCL = ({
  id,
  label,
  ariaRequired,
  required,
  options,
  input,
  helperText,
  'data-test': dataTest,
  placeholder = SelectField.GENERIC,
  meta: { touched, error },
  ...rest
}: ReduxFieldProps & SelectFieldBCLProps) => {
  const { onBlur, value, onChange } = input;
  const hasError = touched && !!error;

  return (
    <SelectBCL
      {...rest}
      id={id}
      label={label}
      onBlur={() => {
        onBlur();
      }}
      onChange={(val) => {
        onChange(val);
      }}
      options={options}
      value={value}
      required={required}
      helperText={helperText}
      errorText={hasError && error}
      aria-required={ariaRequired}
      placeholder={placeholder}
      data-test={dataTest}
      className="align-top"
    />
  );
};

export type TermSelectProps = {
  id: string;
  selectedProductTerms: number[];
  allTerms: Term[];
  classes?: ClassNameMap;
  value: string;
  placeholder?: string;
  onChange?: any;
} & FieldProps;

export const renderTermSelectField: React.ComponentType<any> = ({
  id,
  input,
  selectedProductTerms,
  allTerms,
  classes,
  placeholder,
  meta: { touched, active, error },
  ...rest
}: TermSelectProps) => (
  <Select
    {...input}
    id={id}
    label="Term"
    data-test="term-select"
    fullWidth
    classes={classes}
    error={touched && !active && !!error}
    helperText={touched && !active && error ? error : ''}
    aria-invalid={touched && !!error}
    FormHelperTextProps={{
      error: touched && !active && !!error,
      id: `${id}${i18n({ inputHelperTextConstants: 'HELPER_TEXT_SUFFIX' })}`,
      component: ErrorWrapper,
    }}
    {...rest}
  >
    <option data-test="term-option" value="" disabled>
      {placeholder}
    </option>
    {allTerms &&
      selectedProductTerms.map((termId) => {
        // NOTE: Converting to string until API normalizes types on backend
        const term = allTerms.find((t) => t.id === `${termId}`);
        if (!term) return null;
        return (
          <option data-test="term-option" key={termId} value={term.productId}>
            {term.description}
          </option>
        );
      })}
  </Select>
);

export const NoPhoneCheck = ({
  input,
  label,
  labelId,
  ariaRequired,
  checked,
}: ReduxFieldProps & CheckProps) => {
  const { onChange, ...restInput } = input;
  const classes = useNoPhoneCheckStyles();
  const checkBoxClasses = useCheckboxStyles();
  return (
    <FormControl component="fieldset" className={classes.phoneCheckField}>
      <FormGroup>
        <FormControlLabel
          id={labelId}
          control={
            <MUIFinalFormCheckbox
              disableRipple
              {...restInput}
              checkedIcon={
                <SVGImage
                  iconComponent={
                    <CheckboxCheckedIconSVG className={checkBoxClasses.checkedIconSVG} role="img" />
                  }
                />
              }
              onChange={onChange}
              value="noPhoneNumber"
              classes={{
                root: classes.checkboxOverride,
              }}
              color="primary"
              inputProps={{
                'aria-labelledby': labelId,
                'data-test': 'noPhoneNumber-check',
                'aria-required': ariaRequired,
              }}
            />
          }
          checked={checked}
          label={label}
          data-test="noPhoneNumber-container"
          classes={{
            root: classes.checkboxFormControlOverride,
            label: classes.checkboxLabel,
          }}
        />
      </FormGroup>
    </FormControl>
  );
};

export const RenderCheck: React.ComponentType<CheckProps & ReduxFieldProps> = ({
  id,
  input,
  labelId,
  ariaRequired,
  classes,
  className,
  inputClassName,
  disabled,
  'data-test': dataTest,
  ...rest
}: ReduxFieldProps & CheckProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const checkBoxClasses = useCheckboxStyles();
  return (
    <div className={className}>
      <FormControlLabel
        {...input}
        id={labelId}
        checked={input.checked}
        disabled={disabled}
        control={
          <MUIFinalFormCheckbox
            disableRipple
            id={id}
            className={inputClassName}
            checkedIcon={
              <SVGImage
                iconComponent={
                  <CheckboxCheckedIconSVG
                    className={checkBoxClasses.checkedIconSVG}
                    aria-hidden="true"
                    role="img"
                  />
                }
              />
            }
            color="primary"
            data-test={dataTest}
            inputProps={{
              'aria-labelledby': labelId,
              'aria-required': ariaRequired,
            }}
          />
        }
        label={rest.label}
      />
    </div>
  );
};

export const RenderCheckBCL: React.ComponentType<CheckProps & ReduxFieldProps> = ({
  id,
  input,
  ariaRequired,
  inputClassName,
  disabled,
  'data-test': dataTest,
  meta,
  showErrorIcon = true,
  isErrorSmallFont = true,
  ...rest
}: ReduxFieldProps & CheckProps) => {
  const { base: baseStyles } = checkboxFormInputBCL;
  const classes = bclTwMerge(baseStyles);
  const { checked, onChange, name, onBlur, onFocus } = input;
  const { label } = rest;
  const { error, touched } = meta;

  const hasError = touched && !!error;
  const errorId = `${id}-checkbox-error`;

  return (
    <div className="py-2 items-start">
      <Checkbox
        className={classes}
        id={id}
        name={name}
        checked={checked}
        label={label}
        onCheckedChange={onChange}
        onBlur={() => onBlur()}
        onFocus={() => onFocus()}
        disabled={disabled}
        aria-required={ariaRequired}
        data-test={dataTest}
        aria-describedby={errorId}
        {...rest}
      />
      <CheckboxFormErrorBCL
        id={errorId}
        hasError={hasError}
        helperTextError={error}
        showIcon={showErrorIcon}
        isSmallFont={isErrorSmallFont}
      />
    </div>
  );
};

const FormControlLabelFix = withStyles({
  root: {},
  label: {
    // Clicking on nested elements of radio button label prevents the value
    // from changing. Note that this would break any labels with nested links.
    pointerEvents: 'none',
  },
})(FormControlLabel);

export type RadioOption = {
  disabled?: boolean;
  key: string;
  label: React.ReactNode;
  value: string;
  checked?: boolean;
} & StyleProps;

export type RadioFieldProps = {
  id: string;
  options: RadioOption[];
  placeholder: string;
  showLabel: boolean;
};

export function RenderRadioField(props: ReduxFieldProps & CheckProps & RadioFieldProps) {
  const {
    fieldsetClass,
    label,
    ariaRequired,
    labelClass,
    options,
    input,
    placeholder,
    disabled,
    showLabel,
    'data-test': dataTest,
  } = props;
  const classes = useRadioStyles();
  const radioOptions = disabled
    ? options.map((option: RadioOption) => ({
        disabled: true,
        ...option,
      }))
    : options;
  return (
    <FormControl component="fieldset" classes={{ root: fieldsetClass }}>
      {showLabel && label ? (
        <FormLabel component="legend" classes={{ root: labelClass }}>
          {label}
        </FormLabel>
      ) : null}
      <RadioGroup
        {...input}
        role="radiogroup"
        data-test={dataTest}
        aria-label={label}
        aria-required={ariaRequired}
      >
        {placeholder ? (
          <FormControlLabelFix
            value=""
            disabled
            control={<Radio disableRipple classes={{ root: classes.radio }} />}
            label={placeholder}
          />
        ) : null}
        {radioOptions.map((option) => (
          <FormControlLabelFix
            htmlFor={`${option.value}LabelId`}
            key={option.value}
            value={option.value}
            disabled={option.disabled}
            control={
              <Radio
                id={`${option.value}LabelId`}
                disableRipple
                classes={{ root: classes.radio }}
              />
            }
            classes={option.classes}
            label={option.label}
            data-test={option.value}
            checked={option.checked}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export const ReduxFormComponents = {
  RenderBirthDateField,
  RenderCheck,
  RenderCheckBCL,
  RenderEmailField,
  RenderRadioField,
  RenderSelectField,
  RenderSelectFieldBCL,
  RenderSSNField,
  RenderTelephoneField,
  RenderPhoneTypeField,
  NoPhoneCheck,
  RenderTextField,
  RenderTextFieldBCL,
  RenderZipcodeField,
};
