import type { AccountDetailsType } from 'Domain/Account/AccountDetails';
import AccountTypes from 'Domain/Account/AccountDetails';
import type { Account } from '../../utilities/types';
import { isEligibleForEPreferences } from '../accountDashboard/accountDashboard.reducer';

const Routes = Object.freeze({
  ACCOUNT_DETAILS_PREFIX: '/accountDetails',
  ADD_EXTERNAL_ACCOUNT_OPTIONS: '/accounts/external/new',
  ADD_EXTERNAL_ACCOUNT_NUM: '/accounts/external/account-num',
  ADD_EXTERNAL_YODLEE: '/accounts/external/fast-link',
  ADD_YODLEE_ACCOUNTS_SUCCESS: '/accounts/external/fast-link/success',
  AUTH_CALLBACK: '/callback',
  CANCEL_APPLICATION: '/cancel',
  CONTACT_US_APPLICATION: '/contact-us',
  CD_INTEREST_PAYMENTS_PREFIX: '/accounts/interest-payments',
  CD_RENEWAL_PREFIX: '/accounts/renew',
  CD_RENEWAL_CONFIRMATION_PREFIX: '/cdrenewal/confirmation',
  CD_RENEWAL_INSTRUCTIONS_PREFIX: '/cdrenewal/instructions',
  CD_BUMP_UP_OVERVIEW: '/cdbumpup/overview',
  CD_BUMP_UP_CONFIRMATION: '/cdbumpup/confirmation',
  CONFIRM_TRANSFER: '/transfers/confirmation',
  COMBINED_STATEMENTS: '/combinedStatements',
  DASHBOARD: '/accounts',
  DECLINE_TRANSFER: '/transfers/decline',
  DOCUMENTS: '/documents',
  DY_HEADER: '/dyHeader',
  EAO: '/accounts/open/form',
  EAO_ACCOUNT_PATCH: '/accounts/open/patch',
  EAO_ACCOUNT_CONFIRMATION: '/accounts/open/confirmation',
  EAO_NEW_ACCOUNT_FUNDING_PREFIX: '/accounts/open/funding',
  EDIT_TRANSFER_PREFIX: '/transfers/edit',
  EXPLORE_PRODUCTS: '/explore',
  EXTERNAL_ACCOUNTS: '/accounts/external',
  HOME: '/',
  VIEW_INBOX: '/view-inbox',
  LOGOUT: '/logout',
  NAO: '/users/new/form',
  NAO_ACCOUNT_PATCH: '/users/new/patch',
  NAO_ACCOUNT_CONFIRMATION: '/users/new/confirmation',
  APPLICATION_INIT_FAIL: '/accounts/open/fail',
  NAO_NEW_ACCOUNT_FUNDING: '/users/new/funding',
  NEW_MESSAGE: '/messages/new',
  NEW_TRANSFER: '/transfers/new',
  OLD_EAO: '/accounts/open',
  OLD_NAO: '/users/new',
  PENDING_APPLICATION: '/accounts/open/pending',
  PROCESSING_APPLICATION: '/accounts/open/processing',
  PROCESSING_APPLICATION_TRANSMIT: '/accounts/open/processing-application',
  CONTACT_SUPPORT: '/contact-support',
  PREFERENCES: '/preferences',
  ETIN_VERIFY_SELF: '/etin/1',
  ETIN_CERTIFY_TAX: '/etin/2',
  PROFILE: '/profile',
  REGISTRATION_FAILED: '/accounts/open/registration-fail',
  REPLY_MESSAGE: '/messages/reply',
  SECURITY: '/security',
  STATEMENTS: '/statements',
  TRANSFERS_DASHBOARD: '/transfers',
  TRIAL_DEPOSITS: '/accounts/external/trial-deposits',
  ACCOUNT_VERIFICATION_FAILED: '/accounts/external/account-verification-failed',
  MAIL_CHECK: '/accounts/external/mail-check',
  TRIAL_DEPOSIT_INSTRUCTIONS: '/accounts/open/funding/deposit-instructions',
  VERIFY_TRANSFER: '/transfers/verify',
  VERIFY_TRIAL_DEPOSITS: '/accounts/external/verify',
  OTP_INITIATE: '/otp/initiate',
  OTP_INITIATE_TRANSMIT: '/otp/initiate-transmit',
  OTP_INITIATE_TRANSMIT_EAO: '/otp/account/initiate-transmit',
  OTP_VALIDATE: '/otp/validate',
  NAO_VERIFY_TRIAL_DEPOSITS: '/accounts/external/verify/nao',
  EAO_VERIFY_TRIAL_DEPOSITS: '/accounts/external/verify/eao',
  WELCOME: '/welcome',
  ACH_EXTERNAL_AGREEMENT_PDF: '/pdf/externalAgreement',
  DIRECT_NEW_ACCOUNT_FUNDING_PREFIX: '/accounts/funding',
  OVERDRAFT_PROTECTION_PREFIX: '/accounts/overdraft',
  OVERDRAFT_PROTECTION_SUCCESS: '/accounts/overdraft/success',
  REMOVE_OVERDRAFT_PROTECTION_SUCCESS: '/accounts/overdraft/removed',
  CANCEL_CARD: '/cancel-card',
  REWARDS: '/rewards',
  ZELLE: '/zelle',
  OFFERS: '/offers',
  ALERTS: '/alerts',
  BENEFICIARIES: '/beneficiaries',
  RATE_AND_TERMS: '/rates-terms',
  ATM_LOCATOR: 'https://www.accelnetwork.com/accel-atm-cash-locator.html',
});

export const FundingSteps = Object.freeze({
  CHOOSE: 'choose',
  YODLEE: 'fast-link',
  ADD_EXTERNAL: 'add-external',
  AMOUNT: 'amount',
  REVIEW: 'review',
  CONFIRMATION: 'confirmation',
  VERIFIED: 'verified',
  YODLEE_ERROR: 'yodlee-error',
  ADD_EXTERNAL_ERROR: 'add-external-error',
});

export const FundingStepsSkip = Object.freeze({
  YODLEE: 'fast-link',
  ADD_EXTERNAL: 'add-external',
});

export const QueryParams = Object.freeze({
  affinityCode: 'UISCode',
  code: 'code',
  dyApiPreview: 'dyApiPreview',
  error: 'error',
  productCode: 'productCode',
  promoCode: 'promo',
  reason: 'reason',
  state: 'state',
  ranSiteID: 'ranSiteID',
});

export type FundingStep = typeof FundingSteps[keyof typeof FundingSteps];

const getAccountIdFromUrl = (): string => {
  const paths = window.location.pathname.split('/');
  const accountId = paths[paths.length - 1];
  return accountId || '';
};

export type AccountDetailsParams = {
  accountId: string;
  type: AccountDetailsType;
};

export type AccountDetailsRouterProps = {
  match: {
    params: AccountDetailsParams;
  };
};

export type MessageDetailsRouterProps = {
  match: {
    params: {
      threadId: string;
    };
  };
};

export type EditTransferRouterProps = {
  match: {
    params: {
      transferId: string;
    };
    path: string;
  };
};

export type CDBumpUpRouterProps = {
  match: {
    params: {
      accountId: string;
      type: AccountDetailsType;
    };
    url: string;
    path: string;
  };
};

export const accountDetailHelpers = {
  buildAccountDetailsUrl: (type: AccountDetailsType, accountId: string): string =>
    `${Routes.ACCOUNT_DETAILS_PREFIX}/${type}/${accountId}`,
  getAccountIdFromUrl,
  getTypeFromAccountDetailUrl: (): AccountDetailsType => {
    const paths = window.location.pathname.split('/');
    const type: AccountDetailsType = paths[paths.length - 2] as
      | 'Checking'
      | 'Savings'
      | 'Certificate of Deposit'
      | 'NONE';
    return type || AccountTypes.NONE;
  },
  // Shape of accountDetailsUrl: /accountDetails/type/accountId
  isAccountDetailsUrl: (path: string): boolean => path.startsWith(Routes.ACCOUNT_DETAILS_PREFIX),
} as const;

export const overdraftProtectionHelpers = {
  getAccountIdFromUrl,
  getOverdraftProtectionUrl: (type: string, accountId: string): string =>
    `${Routes.OVERDRAFT_PROTECTION_PREFIX}/${type}/${accountId}`,
  isOverdraftProtectionUrl: (path: string): boolean =>
    path.startsWith(Routes.OVERDRAFT_PROTECTION_PREFIX),
} as const;

export const statementsHelpers = {
  getAccountIdFromUrl,
  getAccountStatementsUrl: (accountId: string): string => `${Routes.STATEMENTS}/${accountId}`,
  isStatementsUrl: (path: string): boolean => path.startsWith(Routes.STATEMENTS),
} as const;

//-----------------------------------------------------------------------------
// CD INTEREST PAYMENTS ROUTE STEPS
//-----------------------------------------------------------------------------

export const CDInterestPaymentsSteps = Object.freeze({
  SELECT_ACCOUNT: 'select-account',
  ADD_EXTERNAL_ACCOUNT: 'add-external-account',
  INTEREST_ACCOUNT_CONFIRMATION: 'interest-account-confirmation',
});

export type InterestPaymentSteps =
  typeof CDInterestPaymentsSteps[keyof typeof CDInterestPaymentsSteps];

export const cdInterestPaymentsHelpers = {
  buildInterestPaymentsUrl: (type: string, accountId: string): string =>
    `${Routes.CD_INTEREST_PAYMENTS_PREFIX}/${type}/${accountId}`,
  isCdInterestPaymentsUrl: (path: string): boolean =>
    path.startsWith(Routes.CD_INTEREST_PAYMENTS_PREFIX),
  buildInterestPaymentsStepUrl: (type: string, accountId: string, step: InterestPaymentSteps) =>
    `${Routes.CD_INTEREST_PAYMENTS_PREFIX}/${type}/${accountId}/${step}`,
  isSelectAccountUrl: (path: string): boolean =>
    path.endsWith(CDInterestPaymentsSteps.SELECT_ACCOUNT),
} as const;

export const messageCenterHelpers = {
  buildMessageCenterReplyUrl: (threadId: string): string => `${Routes.REPLY_MESSAGE}/${threadId}`,
  isReplyMessageUrl: (path: string) => path.startsWith(Routes.REPLY_MESSAGE),
} as const;

export const EditTransferSteps = Object.freeze({
  DETAILS: 'details',
  VERIFY: 'verify',
  CONFIRMATION: 'confirmation',
});

export type EditTransferPathSteps = typeof EditTransferSteps[keyof typeof EditTransferSteps];

export const editTransferHelpers = {
  buildEditTransferUrl: (transferId: string): string =>
    `${Routes.EDIT_TRANSFER_PREFIX}/${transferId}`,
  buildEditTransferStepUrl: (transferId: string, step: EditTransferPathSteps): string =>
    `${Routes.EDIT_TRANSFER_PREFIX}/${transferId}/${step}`,
  isEditTransfersUrl: (path: string) => path.startsWith(Routes.EDIT_TRANSFER_PREFIX),
} as const;

const validFundingFlow = (path: string, numberPaths: number) => {
  // startsWith already validated, we need to check the last string if it exists to make sure it is a valid step
  // the entry step to funding does not have a step in the url so will validate with one less on the length
  const paths = path.split('/');
  return (
    paths.length === numberPaths - 1 ||
    (paths.length === numberPaths &&
      Object.values<string>(FundingSteps).includes(paths[numberPaths - 1]))
  );
};

const stepNumberValue = (stepNumber) => {
  return stepNumber ? `/${stepNumber}` : '';
};

export const accountFundingHelpers = {
  getDirectFundingUrl: (accountId?: string, stepNumber?: number): string =>
    `${Routes.DIRECT_NEW_ACCOUNT_FUNDING_PREFIX}/${accountId || ''}${stepNumberValue(stepNumber)}`,
  getEAOFundingUrl: (accountId?: string, stepNumber?: number): string =>
    `${Routes.EAO_NEW_ACCOUNT_FUNDING_PREFIX}/${accountId || ''}${stepNumberValue(stepNumber)}`,
  getNAOFundingUrl: (stepNumber?: number): string =>
    `${Routes.EAO_NEW_ACCOUNT_FUNDING_PREFIX}/${stepNumberValue(stepNumber)}`,
  isDirectFunding: (path: string): boolean =>
    path.startsWith(Routes.DIRECT_NEW_ACCOUNT_FUNDING_PREFIX) && validFundingFlow(path, 5),
  isEAOFunding: (path: string): boolean =>
    path.startsWith(Routes.EAO_NEW_ACCOUNT_FUNDING_PREFIX) && validFundingFlow(path, 6),
  isNAOFunding: (path: string): boolean =>
    path.startsWith(Routes.NAO_NEW_ACCOUNT_FUNDING) && validFundingFlow(path, 5),
} as const;

//-----------------------------------------------------------------------------
// CD RENEWAL ROUTE STEPS
//-----------------------------------------------------------------------------

export const CDRenewalSteps = Object.freeze({
  ADD_FUNDS: 'add-funds',
  ADD_AMOUNT: 'add-amount',
  CONFIRMATION: 'confirmation',
  TERMS_ADJUSTMENT: 'terms-adjustment',
  FUNDS_ADJUSTMENT: 'funds-adjustment',
  OVERVIEW: 'overview',
  VERIFY: 'verify',
  WITHDRAW_FUNDS: 'withdraw-funds',
  WITHDRAW_AMOUNT: 'withdraw-amount',
  YODLEE: 'fast-link',
  INSTRUCTIONS: 'instructions',
});

export type RenewalSteps = typeof CDRenewalSteps[keyof typeof CDRenewalSteps];

export const cdRenewalHelpers = {
  buildOverviewUrl: (accountId: string) =>
    `${Routes.CD_RENEWAL_PREFIX}/${accountId}/${CDRenewalSteps.OVERVIEW}`,
  isCdRenewalFlow: (path: string): boolean =>
    path.startsWith(Routes.CD_RENEWAL_PREFIX) ||
    path.startsWith(Routes.CD_RENEWAL_CONFIRMATION_PREFIX) ||
    path.startsWith(Routes.CD_RENEWAL_INSTRUCTIONS_PREFIX),
  buildConfirmationUrl: (accountId: string) =>
    `${Routes.CD_RENEWAL_CONFIRMATION_PREFIX}/${accountId}`,
  buildRenewalInstructionsUrl: (accountId: string) =>
    `${Routes.CD_RENEWAL_INSTRUCTIONS_PREFIX}/${accountId}`,
  buildRenewalStepUrl: (accountId: string, step: RenewalSteps) =>
    `${Routes.CD_RENEWAL_PREFIX}/${accountId}/${step}`,
} as const;

//-----------------------------------------------------------------------------
// CD BUMP UP ROUTE HELPERS
//-----------------------------------------------------------------------------
export const cdBumpUpHelpers = {
  isCDBumpUpFlow: (path: string): boolean =>
    path.startsWith(Routes.CD_BUMP_UP_OVERVIEW) || path.startsWith(Routes.CD_BUMP_UP_CONFIRMATION),
  buildCDBumpUpOverviewUrl: (accountId: string, type: string) =>
    `${Routes.CD_BUMP_UP_OVERVIEW}/${type}/${accountId}`,
  buildCDBumpUpConfirmationUrl: (accountId: string, type: string) =>
    `${Routes.CD_BUMP_UP_CONFIRMATION}/${type}/${accountId}`,
} as const;

export const isNewAccountFlow = (path: string): boolean => path.startsWith(Routes.NAO);
export const isExistingAccountFlow = (path: string): boolean => path.startsWith(Routes.EAO);

export const showEpreferencesLink = (accounts: Account[]): boolean =>
  isEligibleForEPreferences(accounts) && window.__config__.IGNITE_PREFERENCES_ENABLED === 'true';

export type Path = typeof Routes[keyof typeof Routes];

// Routes where we do not run either the activity timer (always) or the token refresh timer (when not logged in)
export const TimerIgnorePaths = [
  Routes.WELCOME,
  Routes.APPLICATION_INIT_FAIL,
  Routes.CANCEL_APPLICATION,
  Routes.CONTACT_US_APPLICATION,
  Routes.PROCESSING_APPLICATION,
  Routes.PROCESSING_APPLICATION_TRANSMIT,
] as const;

export default Routes;
