import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { Grid, Hidden } from '@material-ui/core';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { ReduxState } from '../../reducers';
import { List } from '../../reducers/lists.reducer';
import { Account, CustomerInfo, ServiceError } from '../../utilities/types';
import {
  FlashMessageVariantType,
  FlashMessageText,
  FlashMessageVariant,
} from '../../components/flashMessage/flashMessage.constants';
import AccountManagementPage from '../../components/accountManagementPage/accountManagementPage';
import { Header2, BodyTextMedium, SubtitleText } from '../../components/typography/typography';
import {
  Beneficiary,
  BeneficiaryAccount,
  PatchBeneficiary,
  BeneficiariesPageTexts,
} from './beneficiaries.constants';
import useEffectOnce from '../../utilities/reactHooks';
import { fetchBeneficiaries, addOrRemoveBeneficiary } from './beneficiaries.service';
import { useBeneficiariesStyles } from './beneficiaries.styles';
import { useActivityTileStyles } from '../../components/activityTile/activityTile.styles';
import { formatAccountNameDots } from '../../formatters/buildAccountName';
import { GhostButton, IconGhostButton } from '../../components/buttons/buttons';
import { BeneficiariesBtn } from '../../components/cms/buttonText.constants';
import Error from '../../components/error/error';
import { useAlignmentStyles } from '../../styles/layout/alignment.styles';
import { ModalText } from '../../components/cms/blockText.constants';
import IgniteFlashMessage from '../../components/flashMessage/IgniteFlashMessage';
import BeneficiaryModal from '../../components/modal/BeneficiaryModal';
import { getStateList } from '../../actions/lists.actions';
import {
  fetchAccounts as fetchAccountsAction,
  fetchBeneficiaryAccounts as fetchBeneficiaryAccountsAction,
} from '../accountDashboard/accountDashboard.actions';
import InfoModal from '../../components/modal/InfoModal';
import FormatBeneficiaryAddress from '../../components/formatBeneficiaryAddress/formatBeneficiaryAddress';
import { updateFormStatus } from '../profile/profile.actions';
import {
  ANALYTICS_BENEFICIARIES_START_FAILURE,
  ANALYTICS_BENEFICIARIES_START_SUCCESS,
  ANALYTICS_BENEFICIARIES_SUBMIT_FAILURE,
  ANALYTICS_BENEFICIARIES_SUBMIT_SUCCESS,
  ANALYTICS_BENEFICIARIES_VIEW_FAILURE,
  ANALYTICS_BENEFICIARIES_VIEW_SUCCESS,
} from '../../analytics/actions';
import pageTrack from '../../analytics/pageAnalytics.constants';
import { fetchCustomerInfo as fetchCustomerInfoAction } from '../newAccountOpening/applications.actions';
import { isIRA } from '../../utilities/accountTypes';
import BeneficiariesIraModule from './beneficiariesIraModule';
import { BeneficiariesModel, BeneficiariesTileList } from './beneficiariesModel';
import storeNextLineItem, {
  generateEditRemoveIdValue,
  afterRemoveGetLineItem,
  setFocusOnAddEdit,
  setFocusOnRemove,
  displayBeneficiariesName,
} from './beneficiaries.utils';
import i18n from '../../strings/i18n';
import ImagesFileNames from '../../images';
import SVGImage from '../../components/svgImage';
import TrustBeneficiariesModal from './TrustBeneficiariesModal';

type StateProps = {
  accounts: Account[];
  beneficiaryAccounts: Account[];
  isAccountsLoading: boolean;
  isBeneficiaryAccountsLoading: boolean;
  states: List;
  customerInfo?: CustomerInfo;
  accountsError?: ServiceError;
};

const mapStateToProps = (state: ReduxState): StateProps => ({
  accounts: state.accounts.data,
  beneficiaryAccounts: state.accounts.beneficiaryAccounts,
  isBeneficiaryAccountsLoading: state.accounts.isBeneficiaryAccountsLoading,
  isAccountsLoading: state.accounts.isLoading,
  states: state.lists.states,
  customerInfo: state.applications.customerInfo,
  accountsError: state.accounts.error,
});

const pageViewActions = Object.freeze({
  viewSuccess: ANALYTICS_BENEFICIARIES_VIEW_SUCCESS,
  viewFailure: ANALYTICS_BENEFICIARIES_VIEW_FAILURE,
  startSuccess: ANALYTICS_BENEFICIARIES_START_SUCCESS,
  startFailure: ANALYTICS_BENEFICIARIES_START_FAILURE,
  removeSuccess: ANALYTICS_BENEFICIARIES_SUBMIT_SUCCESS,
  removeFailure: ANALYTICS_BENEFICIARIES_SUBMIT_FAILURE,
});
type PageViewAction = typeof pageViewActions[keyof typeof pageViewActions];
type DispatchProps = {
  fetchDashboardAccounts: () => void;
  fetchBeneficiaryAccounts: () => void;
  fetchStateList: () => void;
  updateFormDirtyStatus: (arg0: boolean) => void;
  recordAnalyticsPageView: (pageViewAction: PageViewAction, payload?: string) => void;
  fetchCustomerInfo: () => void;
};

const mapDispatchToProps = (dispatch: ThunkDispatch<null, null, AnyAction>): DispatchProps => ({
  fetchDashboardAccounts: () => {
    dispatch(
      fetchAccountsAction({
        purpose: 'dashboard',
      })
    );
  },
  fetchBeneficiaryAccounts: () => {
    dispatch(
      fetchBeneficiaryAccountsAction({
        purpose: 'beneficiary',
      })
    );
  },
  fetchStateList: () => {
    dispatch(getStateList());
  },
  updateFormDirtyStatus: (isDirty) => {
    dispatch(updateFormStatus(!!isDirty));
  },
  recordAnalyticsPageView: (pageViewAction: PageViewAction, payload?: string) => {
    dispatch({
      type: pageViewAction,
      payload,
    });
  },
  fetchCustomerInfo: () => {
    dispatch(fetchCustomerInfoAction());
  },
});

type BeneficiaryListType = {
  beneficiarysAccounts: BeneficiaryAccount[];
};

const BeneficiaryList = (beneficiaryAccount: BeneficiaryListType) => {
  const { beneficiarysAccounts } = beneficiaryAccount;
  return (
    <ul>
      {beneficiarysAccounts.length !== 0 &&
        beneficiarysAccounts.map((account) => (
          <li key={account.accountId}>
            {formatAccountNameDots(
              account.accountNickname,
              account.accountDisplayType,
              account.accountLastFour
            )}
          </li>
        ))}
    </ul>
  );
};

type SubHeaderDescriptionType = {
  beneficiaryAccount: Account[];
};

const SubHeaderDescription = (beneficiaryAccountProps: SubHeaderDescriptionType) => {
  return (
    <p data-test="beneficiaries-description">
      {beneficiaryAccountProps.beneficiaryAccount?.length
        ? BeneficiariesPageTexts.DESCRIPTION
        : BeneficiariesPageTexts.NO_ACTIVE_ACCOUNTS}
    </p>
  );
};

const refreshInitialData = (shouldRefetchData: boolean, getInitialData: () => Promise<void>) => {
  if (shouldRefetchData) {
    getInitialData();
  }
};

const dashboardAccountRefetch = (
  accounts: Account[],
  fetchDashboardAccounts: (purpose) => void
) => {
  if (!accounts.length) {
    try {
      fetchDashboardAccounts({
        purpose: 'dashboard',
      });
    } catch (err) {
      return err?.data?.message || FlashMessageText.GENERIC_ERROR;
    }
  }
  return undefined;
};

type AllProps = StateProps & DispatchProps;
export const Beneficiaries = ({
  accounts,
  beneficiaryAccounts,
  fetchDashboardAccounts,
  fetchBeneficiaryAccounts,
  fetchStateList,
  updateFormDirtyStatus,
  isAccountsLoading,
  isBeneficiaryAccountsLoading,
  states,
  recordAnalyticsPageView,
  customerInfo,
  fetchCustomerInfo,
  accountsError,
}: AllProps) => {
  // Full-Page
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null | undefined>();
  const [beneficiaries, setBeneficiaries] = useState<Beneficiary[] | null | undefined>();
  const [accountsMissingBeneficiaries, setAccountsMissingBeneficiaries] = useState<
    Account[] | null | undefined
  >();
  const [flashMessage, setFlashMessage] = useState<
    | {
        messageType: FlashMessageVariantType;
        messageText: React.ReactNode;
      }
    | null
    | undefined
  >();
  const [isCustomerLoading, setIsCustomerLoading] = useState(false);
  const [hasIraAccounts, setHasIraAccounts] = useState(false);
  // Add Beneficiary
  const [isAddMode, setIsAddMode] = useState(false);
  // Edit Beneficiary
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [hasModalError, setHasModalError] = useState(false);
  const [beneficiaryEditing, setBeneficiaryEditing] = useState<Beneficiary | null | undefined>();
  const [focusEditButton, setFocusEditButton] = useState(null);
  // Remove Beneficiary
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const [beneficiaryToRemove, setBeneficiaryToRemove] = useState<Beneficiary | null | undefined>();
  const [hasRemovedBeneficiary, setHasRemovedBeneficiary] = useState(false);
  const [openTrustBeneficiaryModal, setOpenTrustBeneficiaryModal] = useState<boolean>(false);

  const classes = useBeneficiariesStyles();
  const activityTileClasses = useActivityTileStyles();
  const alignmentClasses = useAlignmentStyles();

  const reFetchCustomerInfo = async () => {
    if (customerInfo) {
      return true;
    }

    setIsCustomerLoading(true);
    setErrorMessage(undefined);

    try {
      fetchCustomerInfo();
    } catch (err) {
      setErrorMessage(err?.data?.message || FlashMessageText.GENERIC_ERROR);
      return false;
    } finally {
      setIsCustomerLoading(false);
    }

    return true;
  };

  const refetchDashboardAccounts = () => {
    const setErrorValue = dashboardAccountRefetch(accounts, fetchDashboardAccounts);
    setErrorMessage(setErrorValue);
  };

  const getInitialData = async () => {
    setIsLoading(true);
    setErrorMessage(undefined);

    try {
      const [beneficiariesResponse] = await Promise.all([
        fetchBeneficiaries(),
        !accounts.length && !isAccountsLoading && refetchDashboardAccounts(),
        !beneficiaryAccounts.length && !isBeneficiaryAccountsLoading && fetchBeneficiaryAccounts(),
        reFetchCustomerInfo(),
      ]);
      recordAnalyticsPageView(pageViewActions.viewSuccess);
      const beneficiariesList = generateEditRemoveIdValue(beneficiariesResponse);
      setBeneficiaries(beneficiariesList);
    } catch (err) {
      recordAnalyticsPageView(pageViewActions.viewFailure);
      setErrorMessage(err?.data?.message || FlashMessageText.GENERIC_ERROR);
    }

    setIsLoading(false);
  };

  useEffectOnce(() => {
    getInitialData();
  });

  const getActiveBeneficiaryAccounts = () => {
    const activeAccounts: Account[] = [];
    beneficiaryAccounts.forEach((account) => {
      if (account.accountStatus === 'Active') {
        activeAccounts.push(account);
      }
    });
    return activeAccounts;
  };

  // Check to see if there are any accounts without beneficiaries
  useEffect(() => {
    if (beneficiaryAccounts.length && beneficiaries) {
      const allBeneficiaryAccounts: BeneficiaryAccount[] = [];
      beneficiaries.forEach((beneficiary) => {
        allBeneficiaryAccounts.push(...beneficiary.accounts);
      });
      const accountsWithoutBeneficiaries: Account[] = [];
      beneficiaryAccounts.forEach((beneficiaryAccount) => {
        const matchingAccount = allBeneficiaryAccounts.find(
          ({ accountId }) => accountId === beneficiaryAccount.accountId
        );

        if (!matchingAccount && beneficiaryAccount.accountStatus === 'Active') {
          accountsWithoutBeneficiaries.push(beneficiaryAccount);
        }
      });

      if (accountsWithoutBeneficiaries.length) {
        setAccountsMissingBeneficiaries(accountsWithoutBeneficiaries);
      } else {
        setAccountsMissingBeneficiaries(undefined);
      }
    }
  }, [beneficiaryAccounts, beneficiaries]);
  // To check if the beneficiary is completely removed or not
  useEffect(() => {
    if (hasRemovedBeneficiary && beneficiaries && beneficiaryToRemove) {
      const filterForRemovedBeneficiary = beneficiaries.filter(
        (beneficiary) => beneficiary.beneficiaryId === beneficiaryToRemove.beneficiaryId
      );

      if (filterForRemovedBeneficiary && filterForRemovedBeneficiary.length !== 0) {
        setFlashMessage({
          messageType: FlashMessageVariant.ERROR,
          messageText: BeneficiariesPageTexts.CUSTOM_BENEFICIARY_ERROR(beneficiaryToRemove),
        });
      } else {
        setFlashMessage({
          messageType: FlashMessageVariant.SUCCESS,
          messageText: FlashMessageText.EDIT_SUCCESS_GENERIC,
        });
      }

      setHasRemovedBeneficiary(false);
    }
  }, [beneficiaryToRemove, beneficiaries, hasRemovedBeneficiary]);
  // Check to see whether there are no active accounts or only IRA accounts
  useEffect(() => {
    const containsIraAccount = accounts.some(
      (account) => isIRA(account.productDisplayType) && account.accountStatus === 'Active'
    );
    setHasIraAccounts(containsIraAccount);
  }, [accounts]);

  const getModalData = async (isAddClicked: boolean) => {
    setIsModalLoading(true);
    const pageSubFunction = isAddClicked
      ? pageTrack.pagesubfunction.ADD
      : pageTrack.pagesubfunction.EDIT;

    try {
      await Promise.all([
        !beneficiaryAccounts.length && !isBeneficiaryAccountsLoading && fetchBeneficiaryAccounts(),
        fetchStateList(),
        !customerInfo && fetchCustomerInfo(),
      ]);
      recordAnalyticsPageView(pageViewActions.startSuccess, pageSubFunction);
    } catch (err) {
      recordAnalyticsPageView(pageViewActions.startFailure, pageSubFunction);
      setHasModalError(true);
      setIsAddMode(false);
      setBeneficiaryEditing(undefined);
    } finally {
      setIsModalLoading(false);
    }
  };

  const proceedToRemoveBeneficiary = async (nextLineItem: Beneficiary) => {
    setFlashMessage(undefined);
    setShowConfirmationModal(false);
    setIsRemoveLoading(true);
    setHasRemovedBeneficiary(false);

    if (!beneficiaryToRemove) {
      return;
    }

    const patchRequest = {
      relationship: {
        beneficiaryId: beneficiaryToRemove.beneficiaryId,
        accounts: beneficiaryToRemove.accounts.map((account) => ({
          accountId: account.accountId,
          accountType: account.accountType,
          action: 'Delete',
        })),
      },
    };
    let response: PatchBeneficiary;

    try {
      response = await addOrRemoveBeneficiary(patchRequest);

      if (response && response.relationship && response.relationship.accounts) {
        setIsRemoveLoading(false);
        recordAnalyticsPageView(pageViewActions.removeSuccess);
        await getInitialData();
        setHasRemovedBeneficiary(true);
        afterRemoveGetLineItem(nextLineItem);
      }
    } catch (err) {
      const currentLineItem = beneficiaryToRemove;
      setFocusOnRemove(currentLineItem);
      recordAnalyticsPageView(pageViewActions.removeFailure);
      setFlashMessage({
        messageType: FlashMessageVariant.ERROR,
        messageText:
          err?.data?.message ||
          BeneficiariesPageTexts.CUSTOM_BENEFICIARY_ERROR(beneficiaryToRemove),
      });
    } finally {
      setIsRemoveLoading(false);
    }
  };

  const onClickRemove = () => {
    setFlashMessage(undefined);
    const nextLineItemValue = storeNextLineItem(beneficiaries, beneficiaryToRemove);
    proceedToRemoveBeneficiary(nextLineItemValue);
  };

  const handleRemoveOnExit = () => {
    setIsRemoveLoading(false);
    setShowConfirmationModal(false);
  };

  const addNewBeneficiary = () => {
    setFlashMessage(undefined);
    setIsAddMode(true);
    getModalData(true);
  };

  // called in the beneficiary modal when the data is successfully submitted
  const onSubmitModalDataSuccessfully = async () => {
    // The form unmounts before it has a chance to set the dirty status back to false
    updateFormDirtyStatus(false);
    setFlashMessage(undefined);
    setBeneficiaryEditing(undefined);
    setIsAddMode(false);
    await getInitialData();
    setFlashMessage({
      messageType: FlashMessageVariant.SUCCESS,
      messageText: FlashMessageText.EDIT_SUCCESS_GENERIC,
    });
    setFocusOnAddEdit(isAddMode, beneficiaryEditing);
  };

  const renderTileListHeader = () => (
    <BeneficiariesTileList beneficiaries={beneficiaries} classes={classes} />
  );

  const renderBeneficiary = (beneficiary: Beneficiary) => {
    const { accounts: beneficiarysAccounts, name, addresses, dateOfBirth } = beneficiary;

    const handleEditBeneficiary = () => {
      if (name !== null || dateOfBirth !== null) {
        setFocusEditButton(null);
        setFlashMessage(undefined);
        setBeneficiaryEditing(beneficiary);
        getModalData(false);
        const editButton: HTMLElement = document.querySelector(`[id=${beneficiary.editId}]`);
        setFocusEditButton(editButton);
      } else {
        setOpenTrustBeneficiaryModal(true);
      }
    };

    const handleRemoveBeneficiary = () => {
      if (name !== null || dateOfBirth !== null) {
        setShowConfirmationModal(true);
        setBeneficiaryToRemove(beneficiary);
        setFlashMessage(undefined);
      } else {
        setOpenTrustBeneficiaryModal(true);
      }
    };

    return (
      <Grid
        container
        item
        xs={12}
        className={classnames(activityTileClasses.itemContainer, classes.itemContainer)}
        alignItems="baseline"
        component="tr"
        key={beneficiary.beneficiaryId}
        data-test="beneficiaries-container"
      >
        <Grid item xs={9} sm={4} component="td" className={activityTileClasses.item}>
          <BodyTextMedium textTransform="capitalize">
            {displayBeneficiariesName(name)}
          </BodyTextMedium>
          <div className={classes.beneficiaryAddress}>
            <SubtitleText textTransform="capitalize">
              {addresses[0] && <FormatBeneficiaryAddress address={addresses[0]} />}
            </SubtitleText>
          </div>
          <Hidden smUp>
            <BeneficiaryList beneficiarysAccounts={beneficiarysAccounts} />
          </Hidden>
        </Grid>
        <Hidden xsDown>
          <Grid item xs={12} sm={5} md={5} component="td" className={activityTileClasses.item}>
            <BeneficiaryList beneficiarysAccounts={beneficiarysAccounts} />
          </Grid>
        </Hidden>
        <Grid
          item
          xs={3}
          md={2}
          component="td"
          className={classnames(activityTileClasses.item, classes.editRemoveCell)}
        >
          <IconGhostButton
            aria-label="Edit Beneficiary"
            className={classes.editButton}
            id={beneficiary.editId}
            onClick={handleEditBeneficiary}
            icon={
              <SVGImage
                imageName={ImagesFileNames.iconPencilSvg}
                className={classes.editIconSvg}
                ariaHidden="true"
              />
            }
            loading={
              beneficiaryEditing &&
              beneficiaryEditing.beneficiaryId === beneficiary.beneficiaryId &&
              isModalLoading
            }
            disabled={isRemoveLoading || isModalLoading}
            data-test="beneficiaries-edit-button"
          >
            {BeneficiariesBtn.EDIT}
          </IconGhostButton>
          <GhostButton
            aria-label="Remove Beneficiary"
            className={classes.removeButton}
            loading={
              isRemoveLoading &&
              beneficiaryToRemove &&
              beneficiaryToRemove.beneficiaryId === beneficiary.beneficiaryId
            }
            disabled={isRemoveLoading || isModalLoading}
            id={beneficiary.removeId}
            onClick={handleRemoveBeneficiary}
            data-test="beneficiaries-remove-button"
          >
            {BeneficiariesBtn.REMOVE}
          </GhostButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <AccountManagementPage
      pageHeading="Beneficiaries"
      loading={isLoading || ((isAccountsLoading || isCustomerLoading) && !isModalLoading)}
      fullHeightLayoutClassName={classes.fullHeightLayout}
    >
      {errorMessage || accountsError ? (
        <Error
          message={errorMessage || FlashMessageText.GENERIC_ERROR}
          center
          onClick={getInitialData}
        />
      ) : (
        <>
          {flashMessage && (
            <IgniteFlashMessage
              variant={flashMessage.messageType}
              className={classes.flashMessage}
              resetScroll
            >
              {flashMessage.messageText}
            </IgniteFlashMessage>
          )}
          {accountsMissingBeneficiaries && (
            <IgniteFlashMessage
              variant={FlashMessageVariant.INFO}
              className={classes.flashMessage}
              resetScroll
            >
              {i18n({ beneficiaries: 'notAdded' })}{' '}
              {accountsMissingBeneficiaries.map((account, index) => (
                <Fragment key={account.accountId}>
                  {formatAccountNameDots(
                    account.nickname,
                    account.productDisplayType,
                    account.accountIdDisplay.split('●●').pop()
                  )}
                  {index !== accountsMissingBeneficiaries.length - 1 && ', '}
                </Fragment>
              ))}
            </IgniteFlashMessage>
          )}
          <Grid
            container
            spacing={3}
            className={classnames(alignmentClasses.allPadding, classes.manageHeader)}
          >
            <Grid item xs={12}>
              <Header2 data-test="beneficiaries-header">{BeneficiariesPageTexts.TITLE}</Header2>
            </Grid>
            <Grid item xs={12}>
              <SubHeaderDescription beneficiaryAccount={beneficiaryAccounts} />
              <IconGhostButton
                icon={<SVGImage imageName={ImagesFileNames.iconPlusCircleSvg} ariaHidden="true" />}
                className={classnames(classes.addBeneficiaryButtonMobile)}
                disabled={isAddMode || isModalLoading || isRemoveLoading}
                onClick={addNewBeneficiary}
                data-test="beneficiaries-add-button"
              >
                {BeneficiariesBtn.ADD_NEW_BENEFICIARY}
              </IconGhostButton>
            </Grid>
          </Grid>
          <BeneficiariesModel
            beneficiaryAccounts={beneficiaryAccounts}
            hasIraAccounts={hasIraAccounts}
            classes={classes}
            beneficiaries={beneficiaries}
            renderTileListHeader={renderTileListHeader}
            renderBeneficiary={renderBeneficiary}
            onClickRemove={onClickRemove}
            showConfirmationModal={showConfirmationModal}
            handleOnExit={handleRemoveOnExit}
          />
          <BeneficiariesIraModule
            beneficiaryAccounts={beneficiaryAccounts}
            alignmentClasses={alignmentClasses}
            classes={classes}
          />
          {(beneficiaryEditing || isAddMode) && customerInfo && (
            <BeneficiaryModal
              accounts={getActiveBeneficiaryAccounts()}
              beneficiary={beneficiaryEditing}
              updateFormDirtyStatus={updateFormDirtyStatus}
              primaryOwnerInfo={customerInfo}
              onCancel={(shouldRefetchData: boolean) => {
                setFocusOnAddEdit(isAddMode, focusEditButton);
                setIsAddMode(false);
                setBeneficiaryEditing(undefined);
                setFlashMessage(undefined);

                refreshInitialData(shouldRefetchData, getInitialData);
              }}
              states={states}
              isAddMode={isAddMode}
              visible={!isModalLoading}
              onSuccess={onSubmitModalDataSuccessfully}
            />
          )}
          <InfoModal
            visible={hasModalError}
            title={ModalText.GENERIC_ERROR_MODAL_TITLE}
            description={FlashMessageText.GENERIC_ERROR}
            onConfirm={() => {
              setHasModalError(false);
            }}
          />
          <TrustBeneficiariesModal
            handleClose={() => {
              setOpenTrustBeneficiaryModal(false);
            }}
            isOpen={openTrustBeneficiaryModal}
          />
        </>
      )}
    </AccountManagementPage>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Beneficiaries);
