import React, { useState, useEffect } from 'react';
import { Grid, InputAdornment } from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { Link } from 'react-router-dom';
import Button from 'bank-component-library/ui/atoms/Button';
import { RenderTextField } from '../finalForm/finalFormInputs';
import { composeValidators } from '../../utilities/validators';
import { useEffectOnce } from '../../utilities/reactHooks';
import useOtpStyles from './transmitOtp.styles';
import type { VerifyOtpType } from '../../containers/security/security.service';
import IgniteFlashMessage from '../flashMessage/IgniteFlashMessage';
import { FlashMessageVariant } from '../flashMessage/flashMessage.constants';
import { checkEmptyOTP, checkValidOTP } from './transmit.validators';
import ConfirmationModal from '../modal/ConfirmationModal';
import i18n from '../../strings/i18n';
import { removeExtraCharacters, otpValidation } from './transmit.utilities';
import IconButton from '../buttons/iconButton';
import { FormHeaderProps } from '../formWithImage/typings';
import ImagesFileNames from '../../images';
import SVGImage from '../svgImage';
import pageTrack from '../../analytics/pageAnalytics.constants';
import pageAnalytics from '../../analytics/pageAnalytics';
import BackButtonWarning from './BackButtonWarning';

type AllProps = VerifyOtpType & FormHeaderProps;

function VerifyOtp(props: AllProps) {
  const {
    selectedTarget,
    localizedContent,
    showErrorContainer,
    error,
    isRetryAuthenticatorEnabled,
    analyticsConstants,
    handleCancel,
    handleDidntReceiveCode,
    handleOtpChange,
    handleVerifyOtp,
    otpAttempts,
  } = props;

  const analyticData = analyticsConstants;
  const classes = useOtpStyles(props);
  const [showModal, setShowModal] = useState(false);
  const [warningClass, setWarningClass] = useState(classes.warningIcon);
  const [isOtpEntered, setIsOtpEntered] = useState(false);

  const modalTitle = i18n({ OTPValidateText: 'OTP_VALIDATE_HEADER_CANCEL' }).toLowerCase();
  const modalBody = i18n({ OTPValidateText: 'OTP_VALIDATE_BODY_CANCEL' }).toLowerCase();

  function handleFormVerifyOTP(e: Event | React.SyntheticEvent<Element, Event>) {
    e.preventDefault();
    if (isOtpEntered) handleVerifyOtp();
  }

  const onSubmit = (e: Event | React.SyntheticEvent<Element, Event>) => {
    e.preventDefault();
  };

  useEffectOnce(() => {
    if (
      showErrorContainer === false &&
      otpAttempts <= 1 &&
      error === '' &&
      localizedContent.phone_security_response === null
    ) {
      return pageAnalytics
        .newPageView({
          account_type: pageTrack.account_type.generic,
          pagekind: pageTrack.pagekind.account_opening,
          pagename: pageTrack.pagename.otp_step2,
          pagefunction: pageTrack.pagefunction.ao_otp,
          pagesubfunction: pageTrack.pagesubfunction.NAO,
        })
        .record();
    }
    return null;
  });

  useEffect(() => {
    if (showErrorContainer === true && error === i18n({ TransmitAnalytics: 'INCORRECT_CODE' })) {
      return pageAnalytics
        .newPageView({
          account_type: pageTrack.account_type.generic,
          pagekind: pageTrack.pagekind.account_opening,
          pagename: pageTrack.pagename.step2_error,
          pagefunction: pageTrack.pagefunction.ao_otp,
          pagesubfunction: pageTrack.pagesubfunction.NAO,
        })
        .record();
    }
    return null;
  }, [showErrorContainer, error]);

  const checkOtp = (value) => {
    const spaceFormatting = value.replace(/\s/g, '');
    const digits = removeExtraCharacters(spaceFormatting);
    const digitsValid = otpValidation(digits);
    if (digitsValid) {
      setWarningClass(classes.warningIcon);
      setIsOtpEntered(true);
      handleOtpChange(digits);
    } else {
      setWarningClass(classes.warningIconError);
    }
    return digits;
  };

  const normalizeDashes = (value?: string | null) => (value ? checkOtp(value) : '');

  const otpValidator = composeValidators(checkEmptyOTP, checkValidOTP);

  const handleFormDidntReceiveCode = (e: Event | React.SyntheticEvent<Element, Event>) => {
    e.preventDefault();
    handleDidntReceiveCode();
  };
  const PhoneNumberLastFourDigit = selectedTarget.slice(-4, selectedTarget.length);

  const handleOtpFieldChange = (value: string) => {
    if (value === '' || value === null) {
      setIsOtpEntered(false);
    }
  };

  return (
    <section id="verifyCodeScreen">
      {showErrorContainer === true && (
        <IgniteFlashMessage variant={FlashMessageVariant.ERROR}>{error}</IgniteFlashMessage>
      )}

      <ConfirmationModal
        confirmText={i18n({ ModalBtnText: 'EXIT_BTN' })}
        exitText={i18n({ ModalBtnText: 'CANCEL_CONTINUE_BTN' })}
        contentText={i18n({ ModalText: 'CANCEL_TRANSMIT_BODY' }, { modalBody })}
        data-test="cancel-modal"
        onExit={() => {
          setShowModal(false);
        }}
        onConfirm={handleCancel}
        titleText={i18n({ ModalText: 'CANCEL_TRANSMIT_TITLE' }, { modalTitle })}
        visible={showModal}
      />

      <header data-test="verify-otp-holder">
        <Grid
          item
          component="div"
          data-test="verify-otp-header"
          xs={12}
          className={classes.headerTitle}
        >
          {i18n({ OTPVerifyFormLabels: 'ENTER_THE_CODE' })}
        </Grid>

        <Grid
          item
          component="div"
          data-test="verify-otp-description"
          xs={12}
          className={classes.headerDescription}
        >
          {i18n({ OTPText: 'ENTER_THE_CODE' })}
          <strong>{i18n({ OTPText: 'SHOW_NUMBER' }, { PhoneNumberLastFourDigit })}</strong>
          {i18n({ OTPText: 'CODE_EXPIRY' })}
        </Grid>

        <BackButtonWarning warningClass={classes.headerSubDescription} />
      </header>

      {isRetryAuthenticatorEnabled && (
        <Grid item component="div" xs={12} className={classes.otpLinksHolder}>
          <Link
            className={classes.otpLinks}
            title={analyticData.didNotReceiveYourCode.title}
            data-type={analyticData.didNotReceiveYourCode.dataType}
            data-object={analyticData.didNotReceiveYourCode.dataObject}
            data-reason={analyticData.didNotReceiveYourCode.dataReason}
            onClick={handleFormDidntReceiveCode}
            to="/"
          >
            {localizedContent.verifyCodeScreen.didNotReceiveCode}
          </Link>
        </Grid>
      )}

      <Form data-test="enter-phone-number-form" onSubmit={onSubmit}>
        {() => {
          return (
            <>
              <Field
                label={i18n({ OTPVerifyFormLabels: 'FIVE_DIGIT_CODE' })}
                placeholderText="-----"
                maxLength={5}
                id="otpCode"
                className="otpCode"
                name={i18n({ TransmitFormLabels: 'ENTER_OTP_INPUT_NAME' })}
                validate={otpValidator}
                component={RenderTextField}
                parse={normalizeDashes}
                data-test="otp-verify-field"
                InputProps={{
                  endAdornment: (
                    <InputAdornment className={warningClass} position="end">
                      <IconButton title="Warning" disabled>
                        <SVGImage imageName={ImagesFileNames.iconErrorSvg} ariaHidden="true" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <OnChange name={i18n({ TransmitFormLabels: 'ENTER_OTP_INPUT_NAME' })}>
                {handleOtpFieldChange}
              </OnChange>
            </>
          );
        }}
      </Form>

      <Grid container spacing={2} className={classes.buttonHolderCL}>
        <Grid item xs={5}>
          <Button
            id="confirmBtn"
            variant="outlined"
            data-test="cancel-otp-selection"
            className={classes.authenticateBtn}
            onClick={() => setShowModal(true)}
          >
            {localizedContent.verifyCodeScreen.cancel}
          </Button>
        </Grid>
        <Grid item xs={5}>
          <Button
            // eslint-disable-next-line react/jsx-no-bind
            onClick={handleFormVerifyOTP}
            data-type={analyticData.verifyCode.dataType}
            data-object={analyticData.verifyCode.dataObject}
            data-reason={analyticData.verifyCode.dataReason}
            className={classes.authenticateBtn}
            id="verifyOTPButton"
            data-test="continue-send-otp"
          >
            {localizedContent.verifyCodeScreen.verifyCode}
          </Button>
        </Grid>
      </Grid>
    </section>
  );
}

export default VerifyOtp;
