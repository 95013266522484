import React from 'react';
import { SimpleModal } from 'bank-component-library/ui/molecules';
import { Z_INDEX_MODAL_BCL } from '../../styles/zIndex';
import i18n from '../../strings/i18n';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

const TrustBeneficiariesModal = (props: Props) => {
  const { isOpen, handleClose } = props;
  return (
    <SimpleModal
      handleClose={handleClose}
      isOpen={isOpen}
      heading={i18n({ beneficiaries: 'trustBeneficiariesModalTitle' })}
      primaryButton={{
        label: 'close',
        onClick: handleClose,
      }}
      zIndex={Z_INDEX_MODAL_BCL}
    >
      {i18n({ beneficiaries: 'trustBeneficiariesModalContent' })}
    </SimpleModal>
  );
};

export default TrustBeneficiariesModal;
