import { withStyles, Slide, Zoom, withWidth } from '@material-ui/core';
import React, { ReactChild, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import type { StyleProps, WidthProps } from '../../utilities/types';
import LoadingIndicator from '../loadingIndicator/loadingIndicator';
import styles from './animatedLoadingOverlay.styles';

type Props = {
  isLoading?: boolean;
  children?: ReactChild;
  variant?: 'page' | 'formWithImage';
  loadingText?: string;
};

const ANIMATION_DELAY = 150;
const SLIDE_DURATION = 250;

export const AnimatedLoadingOverlay = ({
  isLoading = false,
  classes = {},
  children = null,
  variant,
  loadingText,
  width,
}: Props & StyleProps & WidthProps) => {
  const overlayRef = useRef(null);
  const [openDoor, setOpenDoor] = useState(false);
  // Don't animate on first render since isLoading starts as false
  const [shouldAnimate, setShouldAnimate] = useState(false);

  const isBiggerThanMedium = width === 'lg' || width === 'xl';

  useEffect(() => {
    // Unsupported in very old browsers, will fallback to 'top: 50%'
    if (!('requestAnimationFrame' in window)) return;

    let rAFId;

    if (shouldAnimate) {
      if (isLoading) {
        setOpenDoor(false);
      } else {
        setTimeout(() => setOpenDoor(true), ANIMATION_DELAY);
      }
    }

    if (!shouldAnimate) {
      setShouldAnimate(true);
    }

    const animate = () => {
      const overlay = overlayRef.current;
      const header = document.getElementById('header');
      const footer = document.getElementById('footer');

      if (overlay && header && footer) {
        const headerBBox = header.getBoundingClientRect();
        const footerBBox = footer.getBoundingClientRect();

        const viewportHeight = window.innerHeight;
        const currentY = window.scrollY;

        const minY = currentY + headerBBox.height;
        const maxY = Math.min(currentY + footerBBox.top, currentY + viewportHeight);
        const centerY = headerBBox.height + (maxY - minY) / 2;

        overlay.style.transform = `translateY(${centerY}px)`;
        overlay.style.top = '0%';
      }

      rAFId = requestAnimationFrame(animate);
    };

    if (isLoading) {
      rAFId = requestAnimationFrame(animate);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (rAFId) {
        cancelAnimationFrame(rAFId);
      }
    };
  }, [overlayRef, isLoading, shouldAnimate]);

  return (
    <>
      {children}
      <>
        <Zoom in={isLoading} timeout={{ enter: 0, exit: ANIMATION_DELAY / 2 }} unmountOnExit>
          <div
            className={classnames(classes.overlay, {
              [classes.formWithImageOverlay]: variant === 'formWithImage',
            })}
          >
            <div ref={overlayRef} className={classes.loadingIndicatorContainer}>
              <LoadingIndicator
                className={classes.loadingIndicator}
                isLoading={isLoading}
                loadingText={loadingText}
              />
            </div>
          </div>
        </Zoom>
        <Slide
          direction="right"
          in={!openDoor}
          timeout={{ enter: 0, exit: SLIDE_DURATION }}
          unmountOnExit
          data-test="slide-animation-right"
        >
          <div
            className={
              variant === 'formWithImage'
                ? classes.formWithImageLeftSlidingPanel
                : classes.leftSlidingPanel
            }
          >
            <Zoom in={!isLoading} timeout={ANIMATION_DELAY}>
              <div className={classes.leftGoldBar} />
            </Zoom>
          </div>
        </Slide>
        <Slide
          direction="left"
          in={!openDoor}
          timeout={{
            enter: 0,
            exit:
              variant === 'formWithImage' && isBiggerThanMedium
                ? SLIDE_DURATION * 2
                : SLIDE_DURATION,
          }}
          unmountOnExit
          data-test="slide-animation-left"
        >
          <div
            className={
              variant === 'formWithImage'
                ? classes.formWithImageRightSlidingPanel
                : classes.rightSlidingPanel
            }
          >
            <Zoom in={!isLoading} timeout={ANIMATION_DELAY} unmountOnExit>
              <div className={classes.rightGoldBar} />
            </Zoom>
          </div>
        </Slide>
      </>
    </>
  );
};

AnimatedLoadingOverlay.displayName = 'AnimatedLoadingOverlay';
export default withWidth()(withStyles(styles)(AnimatedLoadingOverlay));
