import React, { lazy } from 'react';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import moment from 'moment';
import {
  connectedRouterRedirect,
  connectedReduxRedirect,
} from 'redux-auth-wrapper/history4/redirect';
import Paths, { QueryParams } from './routes.constants';
import type { AccountDetailsRouterProps } from './routes.constants';
import ExternalAgreementPdf from '../pdfs/externalAgreementPdf.container';
import {
  ACCESS_TOKEN_STORAGE_KEY,
  buildLogoutUrl,
  buildLoginUrl,
  isTokenAuthenticated,
  parseStateParam,
  clearAccessTokens,
} from '../../utilities/authentication';
import { clearSessionID } from '../../utilities/sessionID';
import { AOFlow } from '../../utilities/accountOpeningFlowType';
import { AFFlow } from '../../utilities/accountFundingFlowType';
import TrialDepositInstructions from '../../components/trialDepositInstructions/trialDepositInstructions';
import MailCheck from '../../components/trialDepositInstructions/mailCheck';
import ContactSupport from '../newAccountOpening/funding/contactSupport/contactSupport';
import NoRoute from '../../components/error/noRoute';
import type { ReduxState } from '../../reducers';
import { getSearchParam } from '../../utilities/url';
import { PageLoading, PageWithCardLoading } from '../../components/page/pageLoading';
import FormWithImageLoading from '../../components/formWithImage/formWithImageLoading';
import {
  CancelApplication,
  ContactUsApplication,
  InitFailApplication,
  PendingApplication,
  RegistrationFailed,
} from '../newAccountOpening/exitApplication/exitApplication.container';
import AccountVerificationFailed from '../../components/trialDepositInstructions/accountVerificationFailed';
import worker from '../../workers/singletonWorker';
import { LoadingOverlay } from '../../components/loadingOverlay/loadingOverlay';
import type { Downtime } from '../accountDashboard/accountDashboard.service';
import Maintenance from '../../components/error/maintenance';
import Rewards from '../rewards/rewards.container';
import { ACTION_UPDATE_LOGIN_REDIRECT_PATH } from '../accountDashboard/accountDashboard.reducer';
import Preferences from '../ePreferences/ePreferences.container';
import AsyncNavigation from '../../components/routing/asyncNavigation';
import InitiateOtp from '../otp/InitiateOtp.container';
import InitiateNewUserTransmitOtp from '../otp/InitiateNewUserTransmitOtp.container';
import InitiateExistingUserTransmitOtp from '../otp/InitiateExistingUserTransmitOtp.container';
import ValidateOtp from '../otp/ValidateOtp.container';
import Alerts from '../accountAlerts/accountAlerts.container';
import CombinedStatements from '../combinedStatements/combinedStatements.container';
import CancelCard from '../cancelCard/cancelCard.container';
import Beneficiaries from '../beneficiaries/beneficiaries.container';
import ViewInbox from '../viewInbox/viewInbox.container';
import Documents from '../documents/documents.container';
import NewMessage from '../messages/composeMessage/newMessage.container';
import ReplyMessage from '../messages/replyMessage/replyMessage.container';
import ExploreProducts from '../exploreProducts/exploreProducts.container';
import getImageSrc from '../../utilities/getImageSrc';
import ImagesFileNames from '../../images';

// Construct images URLs
const cdRenewalAndIntPaymentPanelImg = getImageSrc(ImagesFileNames.cdRenewalAndInterestPaymentsJpg);
const transfersPanelImage = getImageSrc(ImagesFileNames.backseatTrunkViewJpg);
const addExtAcctPanelImage = getImageSrc(ImagesFileNames.addExternalAccountsJpg);
const accountOpeningPanelImage = getImageSrc(ImagesFileNames.accountOpeningJpg);

//-----------------------------------------------------------------------------
// HIGHER ORDER FUNCTIONS
//-----------------------------------------------------------------------------

const redirectPathQueryKey = 'redirect_path';

const userIsAuthenticated = connectedReduxRedirect({
  authenticatedSelector: () => isTokenAuthenticated(ACCESS_TOKEN_STORAGE_KEY),
  redirectPath: (reduxState, props) => {
    const { pathname, search } = props.location;
    return `${Paths.HOME}?${redirectPathQueryKey}=${encodeURIComponent(pathname + search)}`;
  },
  // The redirectAction strips the redirectPathQueryKey from the newLocation and stores it in
  // the redux state instead, to be consumed by the HOME route. Exposing the redirectPath directly
  // on the url and consuming it directly off the url leaves the web app vulnerable to a phising
  // attack, if someone were sent to, e.g., synchronybank.com/?redirect_path=www.malicioussite.com
  redirectAction: (newLocation) => (dispatch) => {
    const { pathname } = newLocation;
    const redirectPath = decodeURIComponent(
      getSearchParam(newLocation, redirectPathQueryKey) || Paths.DASHBOARD
    );
    dispatch({
      type: ACTION_UPDATE_LOGIN_REDIRECT_PATH,
      payload: redirectPath,
    });
    dispatch(replace(pathname));
  },
  allowRedirectBack: false,
  wrapperDisplayName: 'UserIsAuthenticated',
});

const userIsNotAuthenticated = connectedRouterRedirect({
  authenticatedSelector: () => !isTokenAuthenticated(ACCESS_TOKEN_STORAGE_KEY),
  redirectPath: (reduxState, props) => {
    const state = getSearchParam(props.location, 'state');
    const fallbackRoute = decodeURI(`${Paths.DASHBOARD}${props.location.search}`);

    if (state) {
      const stateObj = parseStateParam(state);
      return stateObj && stateObj.url ? `${stateObj.url}?state=${state}` : fallbackRoute;
    }
    return fallbackRoute;
  },
  allowRedirectBack: false,
  wrapperDisplayName: 'UserIsNotAuthenticated',
});

type ChunkLoader = () => Promise<any>;

// Creates an authenticated FormWithImage loading component for a chunk loader
const authedFormWithImageLoading = (chunkLoader: ChunkLoader, rightPanelImageUrl: string) =>
  userIsAuthenticated(
    FormWithImageLoading({
      loading: LoadingOverlay,
      loader: () => chunkLoader().then((module) => module.default || module),
      imageUrl: rightPanelImageUrl,
    })
  );

const naoLoading = (chunkLoader: ChunkLoader, rightPanelImageUrl: string) =>
  FormWithImageLoading({
    loading: LoadingOverlay,
    loader: chunkLoader,
    showMarketingMessage: true,
    imageUrl: rightPanelImageUrl,
  });

// Creates an authenticated Page loading component for a chunk loader
const authedPageLoading = (chunkLoader: ChunkLoader) =>
  userIsAuthenticated(
    PageLoading({
      loader: () => chunkLoader().then((module) => module.default || module),
    })
  );

const authedPageWithCardLoading = (chunkLoader: ChunkLoader) =>
  userIsAuthenticated(
    PageWithCardLoading({
      loader: () => chunkLoader().then((module) => module.default || module),
    })
  );

//-----------------------------------------------------------------------------
// DYNAMICALLY LOADED ROUTES
//-----------------------------------------------------------------------------

const AuthenticationCallback = lazy(() => import('../authenticate/authenticate.container'));

const WelcomeContainer = lazy(() => import('../welcome/welcome.container'));

const VerifyTrialDeposits = authedFormWithImageLoading(
  () =>
    import(
      /* webpackChunkName: "verify-trial-deposits" */ '../verifyTrialDeposits/verifyTrialDeposits.container'
    ),
  addExtAcctPanelImage
);
const ServicingVerifyTrialDeposits = (routeProps) => (
  <VerifyTrialDeposits {...routeProps} afFlow={AFFlow.DIRECT} />
);
const NAOVerifyTrialDeposits = (routeProps) => (
  <VerifyTrialDeposits {...routeProps} afFlow={AFFlow.NAO} />
);
const EAOVerifyTrialDeposits = (routeProps) => (
  <VerifyTrialDeposits {...routeProps} afFlow={AFFlow.EAO} />
);

const importAOChunk = () =>
  import(/* webpackChunkName: "account-opening" */ '../newAccountOpening/chunk');

const ProcessingApplication = naoLoading(
  () => importAOChunk().then((module) => module.ProcessingApplication),
  accountOpeningPanelImage
);

const ProcessingApplicationTransmit = naoLoading(
  () => importAOChunk().then((module) => module.ProcessingApplicationTransmit),
  accountOpeningPanelImage
);
const PatchApplication = naoLoading(
  () => importAOChunk().then((module) => module.PatchApplication),
  accountOpeningPanelImage
);
const SuccessConfirmation = naoLoading(
  () => importAOChunk().then((module) => module.SuccessConfirmation),
  accountOpeningPanelImage
);

const NaoPatchApplication = (routeProps) => (
  <PatchApplication {...routeProps} aoFlow={AOFlow.NAO} />
);
const EaoPatchApplication = (routeProps) => (
  <PatchApplication {...routeProps} aoFlow={AOFlow.EAO} />
);

const NewAccountFunding = naoLoading(
  () => importAOChunk().then((module) => module.NewAccountFunding),
  accountOpeningPanelImage
);
const NAOFunding = userIsAuthenticated(() => <NewAccountFunding afFlow={AFFlow.NAO} />);
const EAOFunding = userIsAuthenticated(() => <NewAccountFunding afFlow={AFFlow.EAO} />);
const DirectFunding = userIsAuthenticated(() => <NewAccountFunding afFlow={AFFlow.DIRECT} />);

const MailACheck = userIsAuthenticated(() => <MailCheck />);

const FundingTrialDepositInstructions = userIsAuthenticated(() => (
  <TrialDepositInstructions isFunding />
));

const ExternalAccountVerificationFailed = userIsAuthenticated(() => <AccountVerificationFailed />);

const NewUserAccountOpening = userIsNotAuthenticated(
  naoLoading(
    () => importAOChunk().then((module) => module.NewUserAccountOpening),
    accountOpeningPanelImage
  )
);
const ExistingUserAccountOpening = userIsAuthenticated(
  naoLoading(
    () => importAOChunk().then((module) => module.ExistingUserAccountOpening),
    accountOpeningPanelImage
  )
);

const importCDRenewalChunk = () =>
  import(/* webpackChunkName: "cd-renewal" */ '../cdRenewal/chunk');

const CDRenewal = authedFormWithImageLoading(
  () => importCDRenewalChunk().then((module) => module.CDRenewal),
  cdRenewalAndIntPaymentPanelImg
);

const CDRenewalConfirmation = authedFormWithImageLoading(
  () => importCDRenewalChunk().then((module) => module.CDRenewalConfirmation),
  cdRenewalAndIntPaymentPanelImg
);

const CDRenewalInstructions = (props) => <CDRenewal {...props} viewInstructions />;

const Zelle = authedFormWithImageLoading(
  () => import(/* webpackChunkName: "zelle" */ '../zelle'),
  transfersPanelImage
);

const Statements = authedPageLoading(
  () => import(/* webpackChunkName: "statements" */ '../statements')
);

const GeneralAccountActivity = authedPageLoading(
  () =>
    import(
      /* webpackChunkName: "account-activity" */ '../accountActivity/accountActivity.container'
    )
);

// Use key attribute to force AccountActivity to unmount and remount when navigating
// from one AccountActivity to another.
const AccountActivity = (routeProps: AccountDetailsRouterProps) => (
  <GeneralAccountActivity {...routeProps} key={routeProps.match.params.accountId} />
);

const importODPChunk = () => import(/* webpackChunkName: "odp" */ '../overdraftProtection');
const ODP = authedFormWithImageLoading(
  () => importODPChunk().then((module) => module.ODP),
  transfersPanelImage
);
const ODPConfirmation = authedFormWithImageLoading(
  () => importODPChunk().then((module) => module.ODPConfirmation),
  transfersPanelImage
);
const ODPRemove = authedFormWithImageLoading(
  () => importODPChunk().then((module) => module.ODPRemove),
  transfersPanelImage
);

const importNewTransferChunk = () =>
  // $FlowFixMew
  import(/* webpackChunkName: "new-transfer" */ '../transfers/newTransfer/index');
const DeclineTransfer = authedFormWithImageLoading(
  () => importNewTransferChunk().then((module) => module.DeclineTransfer),
  transfersPanelImage
);
const NewTransfer = authedFormWithImageLoading(
  () => importNewTransferChunk().then((module) => module.NewTransfer),
  transfersPanelImage
);
const TransferConfirmation = authedFormWithImageLoading(
  () => importNewTransferChunk().then((module) => module.TransferConfirmation),
  transfersPanelImage
);
const VerifyTransfer = authedFormWithImageLoading(
  () => importNewTransferChunk().then((module) => module.VerifyTransfer),
  transfersPanelImage
);

const importAddExternalAccountChunk = () =>
  import(/* webpackChunkName: "add-external-account" */ '../addExternalAccount');
const AddExternalAccount = authedFormWithImageLoading(
  () => importAddExternalAccountChunk().then((module) => module.AddExternalAccount),
  addExtAcctPanelImage
);
const ChooseExternalAccountOption = authedFormWithImageLoading(
  () => importAddExternalAccountChunk().then((module) => module.ChooseExternalAccountOption),
  addExtAcctPanelImage
);
const AddYodleeAccount = authedFormWithImageLoading(
  () => importAddExternalAccountChunk().then((module) => module.AddYodleeAccount),
  addExtAcctPanelImage
);
const YodleeResponse = authedFormWithImageLoading(
  () => importAddExternalAccountChunk().then((module) => module.YodleeResponse),
  addExtAcctPanelImage
);

const importEtinChunk = () => import(/* webpackChunkName: "etin" */ '../etin');
const EtinVerifySelf = authedPageWithCardLoading(() =>
  importEtinChunk().then((module) => module.EtinVerifySelf)
);
const EtinCertifyTax = authedPageWithCardLoading(() =>
  importEtinChunk().then((module) => module.EtinCertifyTax)
);

const ExternalAccounts = authedPageLoading(
  () =>
    import(
      /* webpackChunkName: "external-accounts" */ '../externalAccountDashboard/externalAccountDashboard.container'
    )
);

const Transfers = authedPageLoading(
  () =>
    // $FlowFixMe
    import(/* webpackChunkName: "transfers" */ '../transfers/transfers.container')
);

const Profile = authedPageLoading(
  () => import(/* webpackChunkName: "profile" */ '../profile/profile.container')
);

const Security = authedPageLoading(
  () => import(/* webpackChunkName: "security" */ '../security/security.container')
);

const importCDInterestPaymentsChunk = () =>
  import(/* webpackChunkName: "cd-interest-payments" */ '../cdInterestPayments');

const CDInterestPayments = authedFormWithImageLoading(
  () => importCDInterestPaymentsChunk().then((module) => module.CDInterestPayments),
  cdRenewalAndIntPaymentPanelImg
);

const CDBumpUpOverviewContainer = authedPageLoading(
  () =>
    import(/* webpackChunkName: "cd-bump-up-overview" */ '../cdBumpUp/cdBumpUpOverview.container')
);

const CDBumpUpConfirmationContainer = authedPageLoading(
  () =>
    import(
      /* webpackChunkName: "cd-bump-up-confirmation" */ '../cdBumpUp/cdBumpUpConfirmation.container'
    )
);

const importEditTransferChunk = () =>
  import(/* webpackChunkName: "edit-transfer" */ '../transfers/editTransfer');

const EditTransfer = authedFormWithImageLoading(
  () => importEditTransferChunk().then((module) => module.EditTransfer),
  transfersPanelImage
);

const VerifyEditTransfer = authedFormWithImageLoading(
  () => importEditTransferChunk().then((module) => module.VerifyEditTransfer),
  transfersPanelImage
);

const ConfirmationEditTransfer = authedFormWithImageLoading(
  () => importNewTransferChunk().then((module) => module.TransferConfirmation),
  transfersPanelImage
);
const AccountDashboard = authedPageLoading(
  () => import(/* webpackChunkName: "dashboard" */ '../accountDashboard/accountDashboard.container')
);

const RateAndTerms = authedPageLoading(
  () =>
    // $FlowFixMe
    import(/* webpackChunkName: "transfers" */ '../rateAndTerms/rateAndTerms.container')
);

//-----------------------------------------------------------------------------
// ROUTE HELPERS
//-----------------------------------------------------------------------------

type RedirectToLoginStateProps = {
  loginRedirectPath?: string;
  location?: string;
};

const mapStateToRedirectToLoginProps = (state: ReduxState): RedirectToLoginStateProps => ({
  loginRedirectPath: state.accounts.loginRedirectPath,
});

type RedirectToLoginProps = RedirectToLoginStateProps;

const redirectToLogin = connect(mapStateToRedirectToLoginProps)((props: RedirectToLoginProps) => {
  const { search } = props.location;
  // The fallback for redirectPath is for the case when someone navigates directly to '/'
  const redirectPath = props.loginRedirectPath || `${Paths.DASHBOARD}${search}`;

  // loginRedirectPath is set when a user is not logged in
  const callback = () => {
    const win: Window = window;
    win.location = buildLoginUrl(redirectPath);
  };
  // Kill the worker in the background before navigating away from the app
  if (worker) {
    worker.terminate();
  }
  return <AsyncNavigation callback={callback} />;
});

const redirectToLogout = () => {
  const callback = () => {
    clearSessionID();
    clearAccessTokens();
    const win: Window = window;
    win.location = buildLogoutUrl(getSearchParam(window.location, QueryParams.reason));
  };

  return <AsyncNavigation callback={callback} />;
};

const isDowntime = (downtime: Downtime) =>
  downtime &&
  moment().isBetween(moment(downtime.start_date_time).utc(), moment(downtime.stop_date_time).utc());

//-----------------------------------------------------------------------------
// ROUTE COMPONENT
//-----------------------------------------------------------------------------

type StateProps = {
  downtime?: Downtime;
};

const mapStateToProps = (state: ReduxState) => ({
  downtime: state.accounts.downtime,
});

export const Routes = ({ downtime }: StateProps) => {
  return (
    <Switch>
      {downtime && isDowntime(downtime) && <Route component={Maintenance} key="maintenance" />}
      <Route
        exact
        path={Paths.HOME}
        component={userIsNotAuthenticated(redirectToLogin)}
        key="home"
      />
      <Route path={Paths.WELCOME} component={WelcomeContainer} key="welcome" />
      <Route path={Paths.AUTH_CALLBACK} component={AuthenticationCallback} key="authCallback" />
      <Route exact path={Paths.LOGOUT} component={redirectToLogout} key="logout" />
      <Route
        exact
        path={Paths.DASHBOARD}
        component={userIsAuthenticated(AccountDashboard)}
        key="dashboard"
      />
      <Route
        path={`${Paths.ACCOUNT_DETAILS_PREFIX}/:type/:accountId`}
        component={AccountActivity}
        key="accountActivity"
      />
      <Route
        exact
        path={Paths.OVERDRAFT_PROTECTION_SUCCESS}
        component={ODPConfirmation}
        key="odpConfirmation"
      />
      <Route
        exact
        path={Paths.REMOVE_OVERDRAFT_PROTECTION_SUCCESS}
        component={ODPRemove}
        key="odpRemove"
      />
      <Route
        path={`${Paths.OVERDRAFT_PROTECTION_PREFIX}/:type/:accountId`}
        component={ODP}
        key="odp"
      />
      <Route
        exact
        path={Paths.EXTERNAL_ACCOUNTS}
        component={ExternalAccounts}
        key="externalAccounts"
      />
      <Route
        exact
        path={Paths.ADD_EXTERNAL_ACCOUNT_OPTIONS}
        component={ChooseExternalAccountOption}
        key="chooseExternalAccount"
      />
      <Route
        exact
        path={Paths.ADD_YODLEE_ACCOUNTS_SUCCESS}
        component={YodleeResponse}
        key="yodleeSuccess"
      />
      <Route
        exact
        path={Paths.ADD_EXTERNAL_ACCOUNT_NUM}
        component={AddExternalAccount}
        key="addExternalAccount"
      />
      <Route exact path={Paths.ADD_EXTERNAL_YODLEE} component={AddYodleeAccount} key="addYodlee" />
      <Route
        exact
        path={`${Paths.VERIFY_TRIAL_DEPOSITS}/:accountId?`}
        component={ServicingVerifyTrialDeposits}
        key="verifyTrialDeposits"
      />
      <Route
        exact
        path={`${Paths.EAO_VERIFY_TRIAL_DEPOSITS}/:accountId`}
        component={EAOVerifyTrialDeposits}
        key="eaoVerifyTrialDeposits"
      />
      <Route
        exact
        path={`${Paths.NAO_VERIFY_TRIAL_DEPOSITS}/:accountId`}
        component={NAOVerifyTrialDeposits}
        key="naoVerifyTrialDeposits"
      />
      <Route
        exact
        path={Paths.TRANSFERS_DASHBOARD}
        component={Transfers}
        key="transfersDashboard"
      />
      <Route exact path={Paths.NEW_TRANSFER} component={NewTransfer} key="newTransfer" />
      <Route exact path={Paths.VERIFY_TRANSFER} component={VerifyTransfer} key="verifyTransfer" />
      <Route
        exact
        path={Paths.CONFIRM_TRANSFER}
        component={TransferConfirmation}
        key="transferConfirmation"
      />
      <Route
        exact
        path={Paths.DECLINE_TRANSFER}
        component={DeclineTransfer}
        key="declineTransfer"
      />
      <Route path={`${Paths.NAO}/:stepId([0-9]+)?`} component={NewUserAccountOpening} key="nao" />
      <Route
        exact
        path={Paths.OLD_NAO}
        component={(props) => <Redirect to={`${Paths.NAO}${props.location.search}`} />}
        key="oldNao"
      />
      <Route
        exact
        path={`${Paths.NAO_ACCOUNT_PATCH}`}
        component={userIsNotAuthenticated(NaoPatchApplication)}
        key="naoPatch"
      />
      <Route
        exact
        path={`${Paths.EAO_ACCOUNT_PATCH}`}
        component={userIsAuthenticated(EaoPatchApplication)}
        key="eaoPatch"
      />
      <Route
        exact
        path={`${Paths.NAO_ACCOUNT_CONFIRMATION}`}
        component={userIsNotAuthenticated(SuccessConfirmation)}
        key="naoConfirmation"
      />
      <Route
        exact
        path={`${Paths.EAO_ACCOUNT_CONFIRMATION}`}
        component={userIsAuthenticated(SuccessConfirmation)}
        key="eaoConfirmation"
      />
      <Route
        exact
        path={Paths.TRIAL_DEPOSITS} // via add New External accounts (non-funding)
        component={userIsAuthenticated(TrialDepositInstructions)}
        key="trialDeposits"
      />
      <Route
        exact
        path={Paths.MAIL_CHECK} // via add New External accounts (non-funding)
        component={userIsAuthenticated(MailACheck)}
        key="send-mail-check"
      />
      <Route
        exact
        path={Paths.ACCOUNT_VERIFICATION_FAILED}
        component={userIsAuthenticated(ExternalAccountVerificationFailed)}
        key="verificationFailed"
      />
      <Route
        exact
        path={Paths.TRIAL_DEPOSIT_INSTRUCTIONS} // via Funding
        component={userIsAuthenticated(FundingTrialDepositInstructions)}
        key="trialDepositInstructions"
      />
      <Route
        path={`${Paths.EAO_NEW_ACCOUNT_FUNDING_PREFIX}/:accountId/:currentStep?`}
        component={EAOFunding}
        key="eaoFunding"
      />
      <Route
        path={`${Paths.NAO_NEW_ACCOUNT_FUNDING}/:currentStep?`}
        component={NAOFunding}
        key="naoFunding"
      />
      <Route
        path={`${Paths.DIRECT_NEW_ACCOUNT_FUNDING_PREFIX}/:accountId/:currentStep?`}
        component={DirectFunding}
        key="directFunding"
      />
      <Route
        path={`${Paths.EAO}/:stepId([0-9]+)?`}
        component={ExistingUserAccountOpening}
        key="eao"
      />
      <Route
        exact
        path={Paths.OLD_EAO}
        component={(props) => <Redirect to={`${Paths.EAO}${props.location.search}`} />}
        key="oldEao"
      />
      <Route
        exact
        path={Paths.PENDING_APPLICATION}
        component={PendingApplication}
        key="pendingApplication"
      />
      <Route
        exact
        path={Paths.REGISTRATION_FAILED}
        component={userIsNotAuthenticated(RegistrationFailed)}
        key="registrationFailed"
      />
      <Route
        exact
        path={Paths.PROCESSING_APPLICATION}
        component={ProcessingApplication}
        key="processingApplication"
      />
      <Route
        exact
        path={Paths.PROCESSING_APPLICATION_TRANSMIT}
        component={ProcessingApplicationTransmit}
        key="processingApplicationTransmit"
      />
      <Route
        exact
        path={Paths.CANCEL_APPLICATION}
        component={CancelApplication}
        key="cancelApplication"
      />
      <Route
        exact
        path={Paths.CONTACT_US_APPLICATION}
        component={ContactUsApplication}
        key="contactUsApplication"
      />
      <Route path={Paths.CONTACT_SUPPORT} component={ContactSupport} key="contactSupport" />
      <Route
        exact
        path={Paths.APPLICATION_INIT_FAIL}
        component={InitFailApplication}
        key="applicationInitFail"
      />
      <Route
        path={`${Paths.STATEMENTS}/:accountId?`}
        component={userIsAuthenticated(Statements)}
        key="statements"
      />
      {window.__config__.COMBINED_STATEMENTS_ENABLED === 'true' && (
        <Route
          exact
          path={Paths.COMBINED_STATEMENTS}
          component={userIsAuthenticated(CombinedStatements)}
          key="combinedStatements"
        />
      )}
      <Route path={Paths.REWARDS} component={userIsAuthenticated(Rewards)} key="rewards" />
      {window.__config__.IGNITE_PREFERENCES_ENABLED === 'true' && (
        <Route
          path={Paths.PREFERENCES}
          component={userIsAuthenticated(Preferences)}
          key="preferences"
        />
      )}
      <Route
        path={Paths.ETIN_VERIFY_SELF}
        component={userIsAuthenticated(EtinVerifySelf)}
        key="etinVerify"
      />
      <Route
        path={Paths.ETIN_CERTIFY_TAX}
        component={userIsAuthenticated(EtinCertifyTax)}
        key="etinCertify"
      />
      <Route exact path={Paths.PROFILE} component={Profile} key="profile" />
      {window.__config__.SECURITY_ENABLED === 'true' && (
        <Route exact path={Paths.SECURITY} component={Security} key="security" />
      )}
      <Route
        exact
        path={Paths.ACH_EXTERNAL_AGREEMENT_PDF}
        component={ExternalAgreementPdf}
        key="achPDF"
      />
      {window.__config__.ZELLE_ENABLED === 'true' && (
        <Route exact path={Paths.ZELLE} component={Zelle} key="zelle" />
      )}
      {window.__config__.CD_RENEWAL_ENABLED === 'true' && [
        <Route
          path={`${Paths.CD_RENEWAL_PREFIX}/:accountId`}
          component={CDRenewal}
          key="cdRenewal"
        />,
        <Route
          path={`${Paths.CD_RENEWAL_CONFIRMATION_PREFIX}/:accountId`}
          component={CDRenewalConfirmation}
          key="cdRenewalConfirmation"
        />,
        <Route
          path={`${Paths.CD_RENEWAL_INSTRUCTIONS_PREFIX}/:accountId`}
          component={CDRenewalInstructions}
          key="cdRenewalInstructions"
        />,
      ]}
      <Route
        exact
        path={`${Paths.CD_BUMP_UP_OVERVIEW}/:type/:accountId`}
        component={CDBumpUpOverviewContainer}
        key="cd-bump-up-overview"
      />
      <Route
        exact
        path={`${Paths.CD_BUMP_UP_CONFIRMATION}/:type/:accountId`}
        component={CDBumpUpConfirmationContainer}
        key="cd-bump-up-confirmation"
      />
      <Route path={Paths.OTP_INITIATE} component={InitiateOtp} key="otpInitiate" />
      <Route
        path={Paths.OTP_INITIATE_TRANSMIT}
        component={InitiateNewUserTransmitOtp}
        key="otpInitiateTransmit"
      />
      <Route
        path={Paths.OTP_INITIATE_TRANSMIT_EAO}
        component={InitiateExistingUserTransmitOtp}
        key="otpInitiateTransmitEao"
      />
      <Route path={Paths.OTP_VALIDATE} component={ValidateOtp} key="otpValidate" />
      <Route path={Paths.ALERTS} component={userIsAuthenticated(Alerts)} key="alerts" />
      {window.__config__.IGNITE_BENEFICIARIES_ENABLED === 'true' && (
        <Route
          path={Paths.BENEFICIARIES}
          component={userIsAuthenticated(Beneficiaries)}
          key="beneficiaries"
        />
      )}
      <Route path={Paths.VIEW_INBOX} component={userIsAuthenticated(ViewInbox)} key="viewInbox" />
      <Route path={Paths.DOCUMENTS} component={userIsAuthenticated(Documents)} key="documents" />
      <Route
        path={Paths.NEW_MESSAGE}
        component={userIsAuthenticated(NewMessage)}
        key="newMessage"
      />
      <Route
        path={`${Paths.REPLY_MESSAGE}/:threadId`}
        component={userIsAuthenticated(ReplyMessage)}
        key="replyMessage"
      />

      <Route
        path={Paths.CANCEL_CARD}
        component={userIsAuthenticated(CancelCard)}
        key="cancelCard"
      />

      {window.__config__.CD_INTEREST_PAYMENTS_ENABLED === 'true' && (
        <Route
          path={`${Paths.CD_INTEREST_PAYMENTS_PREFIX}/:type/:accountId`}
          component={CDInterestPayments}
          key="cdInterestPayments"
        />
      )}
      {window.__config__.EDIT_TRANSFER_ENABLED === 'true' && [
        <Route
          exact
          path={`${Paths.EDIT_TRANSFER_PREFIX}/:transferId/verify`}
          component={VerifyEditTransfer}
          key="verifyEditTransfer"
        />,
        <Route
          exact
          path={`${Paths.EDIT_TRANSFER_PREFIX}/:transferId/confirmation`}
          component={ConfirmationEditTransfer}
          key="confirmationEditTransfer"
        />,
        <Route
          path={`${Paths.EDIT_TRANSFER_PREFIX}/:transferId`}
          component={EditTransfer}
          key="editTransfer"
        />,
      ]}
      <Route
        path={Paths.EXPLORE_PRODUCTS}
        component={userIsAuthenticated(ExploreProducts)}
        key="exploreProducts"
      />
      {window.__config__.RATE_AND_TERMS === 'true' && (
        <Route
          path={Paths.RATE_AND_TERMS}
          component={userIsAuthenticated(RateAndTerms)}
          key="rateAndTerms"
        />
      )}
      <Route component={NoRoute} key="noRoute" />
    </Switch>
  );
};

export default connect(mapStateToProps)(Routes);
